<script lang="ts">
import "@cisco-u/checkbox/cu-checkbox.js";
import "@cisco-u/radio-buttons/cu-radio-buttons.js";

import { sortBy, startCase, uniqBy, upperFirst } from "lodash-es";
import { defineComponent } from "vue";

import expandCollapseHeading from "@/views/Search/SearchFilterSidebar/expandCollapseHeading.vue";
import FilterCount from "@/views/Search/SearchFilterSidebar/FilterCount.vue";

export default defineComponent({
  components: { expandCollapseHeading, FilterCount },

  emits: ["filter-selected", "expanded", "initFilters"],

  expose: ["selectedFilters", "collapse"],

  props: {
    topic: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      filters: [] as string[],
      expanded: true,
    };
  },
  computed: {
    topicData() {
      if (!this.topic || !this.topic.data) {
        return [];
      }
      let topicD = this.topic.data;

      if (this.topic.name != "07_number_of_credits") {
        topicD = uniqBy(topicD, "value");
      }

      let orderByCount = sortBy(topicD, [
        function (o) {
          return o.count;
        },
      ]).reverse();

      if (this.topic.name == "03_job_roles") {
        return orderByCount.filter((value) => value);
      }

      if (this.topic.name == "10_certification_level") {
        let isEntryAvailable = orderByCount.filter((record) => {
          return record.value == "Entry";
        });
        let isAssociateAvailable = orderByCount.filter((record) => {
          return record.value == "Associate";
        });
        let isProfessionalAvailable = orderByCount.filter((record) => {
          return record.value == "Professional";
        });
        let isExpertAvailable = orderByCount.filter((record) => {
          return record.value == "Expert";
        });
        return [
          isEntryAvailable[0],
          isAssociateAvailable[0],
          isProfessionalAvailable[0],
          isExpertAvailable[0],
        ].filter((value) => value);
      }

      if (this.topic.name == "03_levels") {
        let isBeginnerAvailable = orderByCount.filter((record) => {
          return record.value == "Beginner";
        });
        let isIntermediateAvailable = orderByCount.filter((record) => {
          return record.value == "Intermediate";
        });
        let isAdvancedAvailable = orderByCount.filter((record) => {
          return record.value == "Advanced";
        });
        let isExpertAvailable = orderByCount.filter((record) => {
          return record.value == "Expert";
        });
        return [
          isBeginnerAvailable[0],
          isIntermediateAvailable[0],
          isAdvancedAvailable[0],
          isExpertAvailable[0],
        ].filter((value) => value);
      }

      if (this.topic.name == "04_publisher") {
        let isCiscoAvailable = orderByCount.filter((record) => {
          return record.value == "Cisco";
        });
        if (!isCiscoAvailable.length) {
          return orderByCount;
        }
        // remove it from the list and add it to the beginning of the list
        orderByCount.splice(
          orderByCount.findIndex((element) => element == isCiscoAvailable[0]),
          1
        );
        orderByCount.unshift(isCiscoAvailable[0]);
      }

      if (this.topic.name == "02_technology") {
        let isOtherAvailable = orderByCount.filter((record) => {
          return record.value == "Other";
        });
        if (!isOtherAvailable.length) {
          return orderByCount;
        }
        // remove it from the list and add it to the end of the list
        orderByCount.splice(
          orderByCount.findIndex((element) => element == isOtherAvailable[0]),
          1
        );
        orderByCount.push(isOtherAvailable[0]);
      }
      if (this.topic.name == "07_number_of_credits") {
        let is1_30Available = orderByCount.filter((record) => {
          return record.value == "1-30";
        });
        let is31_60Available = orderByCount.filter((record) => {
          return record.value == "31-60";
        });
        let is61_120lAvailable = orderByCount.filter((record) => {
          return record.value == "61-120";
        });

        return [
          is1_30Available[0],
          is31_60Available[0],
          is61_120lAvailable[0],
        ].filter((value) => value);
      }

      return orderByCount.filter(
        (filter) => filter.value && filter.value.length
      );
    },
    filterName() {
      let topic = this.topic.name;
      topic = topic.split("_").slice(1).join("_");
      return topic;
    },
    routeQueryTopic() {
      return this.$route.query && this.$route.query[this.filterName]
        ? this.$route.query[this.filterName]
        : [];
    },
    displayName() {
      let topic = this.filterName;
      topic = startCase(topic);

      if (topic === "Number Of Credits") {
        topic = "CE Credits";
      }
      if (topic === "Levels") {
        topic = "Skill Levels";
      } else if (topic === "Certification Level") {
        topic = "Certification Levels";
      }
      // change the display topic for Job Roles
      if (topic === "Job Roles") {
        topic = "Roles";
      }
      return topic;
    },
    hasFilterableResults() {
      if (this.topic?.data) {
        if (this.topic.name == "07_number_of_credits") {
          return (
            this.topic.data
              ?.map((result: any) => {
                result.value = result.name;
                return result;
              })
              .filter((result: any) => {
                return result && result.count;
              }).length > 0
          );
        }
        return (
          this.topic.data?.filter((result: any) => {
            return result && result.value && result.value != "";
          }).length > 0
        );
      }
      return false;
    },
  },
  mounted() {
    this.filters = [this.routeQueryTopic as string[]].flat();
  },
  methods: {
    caseAdjust(value: string) {
      if (value.toLowerCase() == "skillsoft") {
        return upperFirst(value.toLowerCase());
      }
      return value;
    },
    checkActive(value: string) {
      return this.routeQueryTopic?.includes(value);
    },
    collapse(expand: boolean) {
      this.expanded = expand;
    },
    selectedFilters() {
      return {
        name: this.filterName,
        keys: JSON.parse(JSON.stringify(this.filters)),
      };
    },
    initFilters() {
      this.filters = [];
    },
    ToggleFromModel(value: string) {
      let internalFilters = JSON.parse(JSON.stringify(this.filters));
      if (!internalFilters) return;

      if (internalFilters.includes(value)) {
        internalFilters = internalFilters.filter(
          (matchingValue: any) => matchingValue !== value
        );
      } else {
        internalFilters.push(value);
      }
      this.filters = internalFilters;
      this.$emit("filter-selected");
    },
    expandCollapseInternal() {
      this.expanded = !this.expanded;
      this.$emit("expanded", true);
    },
  },
});
</script>

<template>
  <div class="mr-10 min-w-[17.5rem]" v-if="hasFilterableResults">
    <div class="mr-4 mt-4 border-t">
      <expandCollapseHeading
        :data-cy="`search-filter-heading-${filterName}`"
        @expand-collapse-internal="expandCollapseInternal"
        :expanded="expanded"
      >
        {{ displayName }}
      </expandCollapseHeading>

      <div :data-cy="`search-filter-${filterName}-field-list`" v-if="expanded">
        <ul class="p-1">
          <li
            v-for="filter in topicData"
            :key="filter.value"
            data-cy="search-field"
            class="flex flex-row items-center justify-between whitespace-nowrap text-16 tracking-[125%] text-black-dark"
          >
            <cu-checkbox
              v-if="filter.count"
              @input="ToggleFromModel(filter.value)"
              :title="`${filter.value} (${filter.count})`"
              :checked="checkActive(filter.value)"
            >
              {{ caseAdjust(filter.value) }}
              <FilterCount>({{ filter.count }})</FilterCount>
            </cu-checkbox>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
