<template>
  <div class="mt-20 lg:mt-0">
    <div class="mb-16 w-[17.5rem] animate-pulse pr-10" v-for="j in 2" :key="j">
      <div class="mb-8 flex items-center justify-between">
        <div class="w-32 rounded-full bg-gray-300 py-2" />
        <div class="h-4 w-4 rounded-full bg-gray-300" />
      </div>

      <div class="mb-2 flex items-center gap-4" v-for="i in 6" :key="i">
        <div class="h-6 w-6 bg-gray-300" />
        <div class="w-32 rounded-full bg-gray-300 py-1" />
      </div>
    </div>
  </div>
</template>
