import type { LocationQuery } from "vue-router";

import http from "@/services/http";
import {
  setFacetFilters,
  setPostPage,
  setTenantAnalyticsUrl,
} from "@/services/searchService/common";
import { SearchRequest } from "@/services/searchService/types";
import { buildFacetsFiltersFromUrlParms } from "@/views/Search/filtersHelper";

// service handler
export async function fetchCertResults(
  postObj: SearchRequest,
  query: LocationQuery
) {
  postObj = setPostPage(postObj);
  const facetFilters = buildFacetsFiltersFromUrlParms(query);
  if (facetFilters) {
    postObj = setFacetFilters(postObj, facetFilters);
  }
  // tag with tentant, analytics, and the url
  postObj = setTenantAnalyticsUrl(postObj);

  // check if the object is valid
  // searchRequest.parse(postObj);

  // request and await response data
  const { data } = await http().post(
    window.env.SEARCH_API_LOCATION + "/cert-search",
    postObj
  );

  return data;
  // check if response is valid
  // return searchCertResponseBody.parse(data);
}

export async function fetchCertFacets(
  postObj: SearchRequest,
  query: LocationQuery
) {
  postObj = setPostPage(postObj);
  const facetFilters = buildFacetsFiltersFromUrlParms(query);
  if (facetFilters) {
    postObj = setFacetFilters(postObj, facetFilters);
  }
  // tag with tentant, analytics, and the url
  postObj = setTenantAnalyticsUrl(postObj);

  // request and await response data
  const { data } = await http().post(
    window.env.SEARCH_API_LOCATION + "/cert-search" + "-facets",
    postObj
  );

  return data;
}

export async function fetchUnauthCertResults(
  postObj: SearchRequest,
  query: LocationQuery
) {
  postObj = setPostPage(postObj);
  const facetFilters = buildFacetsFiltersFromUrlParms(query);
  if (facetFilters) {
    postObj = setFacetFilters(postObj, facetFilters);
  }
  // tag with tentant, analytics, and the url
  postObj = setTenantAnalyticsUrl(postObj);

  // check if the object is valid
  // searchRequest.parse(postObj);

  // request and await response data
  const { data } = await http().post(
    window.env.UNAUTH_API + "/certification-search",
    postObj
  );

  // check if response is valid
  // return searchCertResponseBody.passthrough().parse(data.certSearchResponse);
  return data.certSearchResponse;
}

export async function fetchUnauthCertFacets(
  postObj: SearchRequest,
  query: LocationQuery
) {
  postObj = setPostPage(postObj);
  const facetFilters = buildFacetsFiltersFromUrlParms(query);
  if (facetFilters) {
    postObj = setFacetFilters(postObj, facetFilters);
  }
  // tag with tentant, analytics, and the url
  postObj = setTenantAnalyticsUrl(postObj);

  // check if the object is valid
  // searchRequest.parse(postObj);

  // request and await response data
  const { data } = await http().post(
    window.env.UNAUTH_API + "/certification-search",
    postObj
  );

  return data.certSearchFacetsResponse;
}
