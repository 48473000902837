<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  methods: {
    returnPrevious() {
      return this.$router.go(-1);
    },
  },
});
</script>
<template>
  <div
    class="mt-8 flex h-[24.313rem] w-[72rem] bg-white-light px-10 py-[3.125rem]"
  >
    <div class="w-3/4">
      <div>
        <h2 class="mb-10 text-29 font-light" data-cy="noresults">
          No Results found
        </h2>
        <div>
          <ul class="mb-5 list-disc text-18" data-cy="noResults-Checklist">
            <li>Check your spelling.</li>
            <li>Enter fewer words, e.g. “cloud” rather than “hybrid cloud”.</li>
            <li>
              Enter similar words, e.g. “network” rather than “networking”.
            </li>
            <li>Deselect filters.</li>
          </ul>
          <div class="mb-8 text-18" data-cy="noresults-Subheading">
            We also invite you to
            <a class="text-blue-light" href="/explore">EXPLORE</a> our catalog.
          </div>
        </div>
        <div>
          <button
            class="rounded-full bg-blue-light px-6 py-2 text-22 font-light text-white-lightest"
            @click="returnPrevious"
            @keyup.enter="returnPrevious"
            @keyup.space="returnPrevious"
            data-cy="returnToPreviousBtn"
          >
            &#8592; Return to previous page
          </button>
        </div>
      </div>
    </div>
    <div>
      <img
        src="@/views/Search/assets/search-noresults.png?url"
        alt="Cisco U."
      />
    </div>
  </div>
</template>
