import { startCase } from "lodash-es";
export default function formatContentTypes(type: string) {
  const resultType = startCase(type);
  if (resultType == "Path" || resultType == "learning-paths") {
    return "Learning Path";
  }
  if (resultType == "Webinar") {
    return "Event";
  }
  if (resultType == "Track") {
    return "Video Series";
  }
  if (type == "instructor-led") {
    return "Instructor-led Training";
  }
  return resultType;
}
