import http from "@/services/http";
import unauthenticatedHttp from "@/services/unauthenticatedHttp";

export async function trackClickThrough(
  postObj: any,
  unauthorized: boolean = false
) {
  if (unauthorized) {
    await unauthenticatedHttp().post(
      window.env.UNAUTH_API + "/search-clickthrough-unauth",
      postObj
    );
  } else {
    await http().post(
      window.env.SEARCH_API_LOCATION + "/search" + "-clickthrough",
      postObj
    );
  }
}
