<script lang="ts">
import "unfonts.css";
import "@/index.css";
import "@cisco-u/footer/cu-footer.js";
import "@cisco-u/top-header/cu-top-header.js";
import "@cisco-u/buttons/cu-buttons.js";
import "@cisco-u/text-select-field/cu-text-select-field.js";

import { defineComponent } from "vue";
import { useCookies } from "vue3-cookies";
import { mapActions, mapGetters, mapMutations } from "vuex";

import cartModal from "@/components/cartModal.vue";
import messageBanner from "@/components/messageBanner.vue";
import PageHeader from "@/layout/Header/PageHeader.vue";
import PageNav from "@/layout/Header/PageNav.vue";
import ProfileHeader from "@/layout/Header/profileHeader.vue";
import ProfileList from "@/layout/Header/profileList.vue";
import PersonalInterestSurvey from "@/layout/InterestSurvey/personalInterestSurvey.vue";
import siteWideMessage from "@/layout/siteWideMessage.vue";
import commerceService from "@/services/commerceService";
import { EventBus } from "@/store/eventBus";
import { sendButtonCtaTelemetry } from "@/utils/ctaTelemetry";
import isUnauth from "@/utils/isUnauth";
import * as redirectCookie from "@/utils/redirectUrlCookie";

import requiredKeys from "../configs/requiredKeys.json";

const cookies = useCookies().cookies;

export default defineComponent({
  name: "App",
  components: {
    ProfileHeader,
    ProfileList,
    PageHeader,
    PersonalInterestSurvey,
    siteWideMessage,
    PageNav,
    cartModal,
    messageBanner,
  },
  data() {
    return {
      scrolled: false,
      showGlobalSuccess: false,
      show: false,
      fixed: false,
      dark: false,
      openCartModal: false,
      courseCard: false as boolean,
      contentData: {} as any,
      query: "" as string,
      Communityfooter: {
        type: "Community",
        list: [
          { name: "Communities", url: "/communities", external: false },
          {
            name: "Events",
            url: "/events",
            external: false,
          },
          { name: "Podcasts", url: "/podcasts", external: false },
          {
            name: "Snack Minute Playlist",
            url: "https://www.youtube.com/playlist?list=PL2AuQ_ZjCjsq_dipCVvUS7enTwk4_P7n5",
            external: true,
          },
        ],
      },
      VPfooterInfo: {
        type: "Plans",
        list: [
          {
            name: "Subscriptions",
            url: "/plans",
            external: false,
          },
          {
            name: "Learning Credits",
            url: "https://www.cisco.com/c/en/us/training-events/training-certifications/training/learning-credits.html",
            external: true,
          },
          {
            name: "Request a Demo",
            url: "https://mkto.cisco.com/ciscou-contact-sales.html?utm_campaign=ciscou&utm_source=web-cu&utm_medium=cu-unauth",
            external: true,
          },
        ],
      },
      joinCiscoText: "Join Cisco U. Free",
      loginText: "Log in with your Cisco ID",
    };
  },
  watch: {
    $route(): void {
      if (!this.showPageNav) {
        this.scrolled = true;
      }
      this.setupScrollWatch();
      this.checkOnboardSurvey();
      this.setRedirect();
      this.authRedirectToHomepage();
    },
    achievements(): void {
      this.combineMyLearning();
    },
    myListResults(): void {
      this.combineMyLearning();
    },
    entitlements(): void {
      this.combineMyLearning();
    },
  },
  computed: {
    ...mapGetters({
      obSurvey: "user/obSurvey",
      userStatus: "user/status",
      showDropDown: "user/showDropDown",
      singleTitleBundles: "pricing/singleTitleBundles",
      allAccessPID: "content/allAccessProductId",
      essentialsPID: "content/essentialsProductId",
      essentialsId: "content/essentialsId",
      essentialsBundle: "content/essentialsBundle",
      allAccessBundle: "content/allAccessBundle",
      achievements: "user/achievements",
      myListResults: "user/myListResults",
      entitlements: "user/entitlements",
    }),
    pageHeading(): string {
      return this.$route && (this.$route.meta?.title as string)
        ? (this.$route.meta.title as string)
        : "";
    },
    showProfile(): boolean {
      if (this.$route?.meta?.scrollShowProfile) {
        return true;
      }
      return this.scrolled;
    },
    showPageNav(): boolean {
      return this.$route?.meta?.pageHeader === true && !this.isUnauth();
    },
    darkHeader(): boolean {
      if (this.isUnauth() && this.$route?.name) {
        return ![
          "certifications",
          "search-all",
          "search-type",
          "all",
          "explore-type",
          "explore-paths",
          "explore-courses",
          "explore-exams",
          "explore-instructorled",
          "explore-tutorials",
          "explore-videos",
          "explore-podcasts",
          "explore-events",
        ].includes(this.$route.name as string);
      }
      return true;
    },
    linkActiveClass(): string {
      return this.darkHeader
        ? "bg-black-darkest text-white-lightest"
        : "bg-white-lightest text-black";
    },
  },

  mounted(): void {
    this.navigateReferrerUrl();
    EventBus.on("openCartModal", (content: any) => {
      this.contentData = content.data;
      this.courseCard = content.courseCard;
      this.openCartModal = true;
    });
    EventBus.on("resetModal", () => {
      this.openCartModal = false;
    });
    let configCheck = new Promise((resolve, reject): void => {
      requiredKeys.forEach((element: string) => {
        if (
          typeof window?.env[element as keyof typeof window.env] ==
            "undefined" &&
          !window.location.href.includes("/error")
        ) {
          if (import.meta.env.DEV && !import.meta.env.TEST) {
            console.log(element);
          }
          EventBus.emit("showBanner", {
            type: "ERROR",
            message: "a missing config value was detected " + element,
          });
          reject(new Error("missing config value"));
        }
      });
      resolve(true);
    });

    configCheck.then(
      async () => {
        this.setRedirect();
        this.authRedirectToHomepage();
        if (!this.isUnauth()) {
          this.requestUserStatus();
          this.setupScrollWatch();
          this.validateAndLogin();
          this.fetchUpcomingClasses();
          this.checkOnboardSurvey();
          try {
            await this.fetchMyListData();
            await this.fetchProgress();
            await this.fetchPrices();
          } catch (error) {
            if (import.meta.env.DEV && !import.meta.env.TEST) {
              console.log(error);
            }
          }
        } else {
          await this.fetchSubscriptions();
          await this.fetchAuthorizedContent();
        }
      },
      () => {
        // intenional
      }
    );
  },

  methods: {
    setRedirect() {
      if (!this.isUnauth()) return;
      redirectCookie.setRedirectUrlCookie(this.$route.fullPath, 1);
    },
    authRedirectToHomepage() {
      if (this.isUnauth() && !this.$route?.meta?.unauth) {
        this.$router.push("/");
      }
    },
    ...mapActions({
      fetchSubscriptions: "pricing/fetchSubscriptions",
      fetchAuthorizedContent: "content/getFreeContent",
      fetchStatus: "user/fetchStatus",
      fetchPrices: "pricing/fetchPrices",
      fetchMyListData: "user/fetchMyListData",
      fetchProgress: "user/fetchProgress",
      fetchUpcomingClasses: "instance/fetchUpcomingClasses",
    }),

    ...mapMutations({
      setMyLearningList: "user/setMyLearningList",
    }),

    navigateReferrerUrl() {
      let referrerUrl = cookies.get("referrer-url");
      if (referrerUrl) {
        referrerUrl = referrerUrl.replace(`${window.location.origin}/`, "");
        if (referrerUrl.includes("unauthenticated.html")) {
          referrerUrl = referrerUrl.replace("unauthenticated.html", "");
        }

        cookies.remove("referrer-url");
        this.$router.push(referrerUrl);
      }
    },

    combineMyLearning() {
      if (
        this.achievements !== null &&
        this.myListResults !== null &&
        this.entitlements !== null
      ) {
        const inProgressList: any[] = this.inProgressHelper(this.achievements);
        const bookmarkList: any[] = this.bookmarkHelper(this.myListResults);
        const singleTitles: any[] = this.singleTitleHelper(this.entitlements);
        const learningList = [
          ...inProgressList,
          ...singleTitles,
          ...bookmarkList,
        ];

        this.setMyLearningList(learningList);
      }
    },

    inProgressHelper(achievements: any) {
      const inProgressList: any[] = [];

      Object.keys(achievements)?.forEach((key: string) => {
        if (key !== "courses") {
          achievements[`${key}`]["In-progress"]?.forEach((item: any) => {
            if (key === "learningpaths") {
              item.type = "path";
            } else if (key === "tutorials") {
              item.type = "tutorial";
            }
            inProgressList.push(item);
          });
        }
      });
      return inProgressList;
    },

    bookmarkHelper(myListResults: any) {
      const bookmarkList: any[] = [];
      myListResults?.forEach((item: any) => {
        const bookmarkedItem = item;
        bookmarkedItem.bookmarked = true;
        bookmarkList.push(bookmarkedItem);
      });
      return bookmarkList;
    },

    singleTitleHelper(entitlements: any) {
      const singleTitles: any[] = [];
      entitlements?.forEach((item: any) => {
        const newItem = item;
        if (newItem.is_single) {
          if (newItem.exam_id) {
            newItem.type = "practice-exam";
          } else if (newItem.path_id) {
            newItem.type = "path";
          }
          singleTitles.push(newItem);
        }
      });
      return singleTitles;
    },

    requestUserStatus() {
      this.fetchStatus();

      // call this again every 5 minutes
      setInterval(() => {
        this.fetchStatus();
      }, 300000);
    },

    setupScrollWatch(): void {
      if (this.showPageNav) {
        this.scrolled = false;
        window.removeEventListener("scroll", () => {
          // intentional
        });
        window.addEventListener("scroll", this.scrollHeader);
      }
    },

    scrollHeader(): void {
      if (this.showPageNav) {
        this.scrolled = document.documentElement.scrollTop >= 438;
      }
    },

    checkOnboardSurvey() {
      // status is loaded thanks to router before route check view-page telem
      this.show =
        this.obSurvey && this.userStatus
          ? !this.userStatus.surveyCompleted
          : false;
      EventBus.emit("updatesurveyCompleted", this.show);
      if (
        this.$route.name == "ForYou" &&
        this.userStatus &&
        !this.userStatus.surveyCompleted
      ) {
        this.fixed = true;
      }
    },

    closeSurvey() {
      this.show = false;
      this.fixed = false;
    },

    returnFeedbackUrl() {
      const userEmail = this.userStatus ? this.userStatus.email : "";
      const userName = this.userStatus ? this.userStatus.display_name : "";
      let envInfo = "";

      switch (window.env.CISCO_U_ENVIRONMENT?.toLowerCase()) {
        case "dev":
          envInfo = "-dev";
          break;
        case "test":
          envInfo = "-test";
          break;
        case "stage":
          envInfo = "-stg";
          break;
      }

      return `https://ciscocx.qualtrics.com/jfe/form/SV_0TH3hJQ3vG2iK3Q?ccoemail=${userEmail}&cconame=${userName}&platform=CiscoU${envInfo}`;
    },
    validateAndLogin() {
      const basketId = localStorage.getItem("BASKET_ID") ?? "NA";
      if (basketId === "NA") {
        this.loginUser();
      } else {
        commerceService
          .validateBasket(basketId)
          .then((data: any) => {
            if (data && data.status != "VALID") {
              this.loginUser();
            }
          })
          .catch((error: any) => {
            if (import.meta.env.DEV && !import.meta.env.TEST) {
              console.log("error validating basket");
            }
          });
      }
    },
    loginUser() {
      commerceService
        .loginUser()
        .then((userData: any) => {
          if (userData && userData.basketId != "") {
            localStorage.setItem("BASKET_ID", userData.basketId);
            EventBus.emit("refreshBasket", userData);
          }
        })
        .catch((error: any) => {
          if (import.meta.env.DEV && !import.meta.env.TEST) {
            console.log("error login user to commerce platform");
          }
        });
    },

    updateQuery(newValue: any) {
      this.query = newValue;
    },

    displayConsentManager() {
      if (
        typeof Optanon !== "undefined" &&
        typeof Optanon.ToggleInfoDisplay === "function"
      ) {
        Optanon.ToggleInfoDisplay();
      }
    },

    handleFooterLink(e: any) {
      if (e.detail?.name?.toLowerCase() === "cookies") {
        e.detail?.event?.preventDefault();
        this.displayConsentManager();
      }
    },

    isUnauth,

    async sendTelemetry(title: string) {
      if (this.isUnauth()) {
        await sendButtonCtaTelemetry(this.$route.fullPath, title, true);
      }
      window.location.href = window.location.origin + "/login";
    },
  },
});
</script>

<template>
  <div id="application" :class="[{ 'fixed overflow-hidden': fixed }]">
    <router-view v-slot="{ Component }" :key="$route.fullPath">
      <transition name="fade" mode="out-in">
        <div
          class="leading-cisco overflow-x-hidden overflow-y-hidden font-cisco font-normal tracking-cisco text-black dark:text-white"
        >
          <header>
            <messageBanner />
            <profile-header
              v-if="!isUnauth()"
              :dark="dark"
              :scrolled="showProfile"
              :show-logo="showProfile"
              :searchQuery="query"
              @close="fixed = false"
              @open="fixed = true"
              @update-query="updateQuery"
            />
            <div
              v-if="isUnauth()"
              :class="[
                'sticky top-0 z-10',
                {
                  'bg-white-lightest': darkHeader,
                  'bg-black-dark': !darkHeader,
                },
              ]"
            >
              <router-link
                v-if="darkHeader || showProfile"
                to="/search/tutorial?query=AI%20chatgpt"
                class="hidden bg-blue-dark px-6 py-4 text-center text-22 leading-[1.2] tracking-[-0.44px] text-white-lightest hover:underline lg:block"
                active-class="underline"
                ><strong class="font-[500]"
                  >Free AI training and tutorials from Cisco U.</strong
                >
                From entry to expert, gain real-world skills to succeed in
                AI.</router-link
              >

              <cu-top-header
                :dark="darkHeader"
                class="mx-auto max-w-[90rem]"
                logoLink="/"
              >
                <router-link
                  v-for="item in [
                    { name: 'Explore', text: 'Explore' },
                    { name: 'certifications', text: 'Certifications' },
                    { name: 'community-landing-page', text: 'Communities' },
                    { name: 'subscription', text: 'Plans' },
                  ]"
                  :key="item.name"
                  tabindex="0"
                  slot="nav-item"
                  :active-class="`underline border-full px-[1.25rem] py-[1rem] ${linkActiveClass}`"
                  class="inline-block whitespace-nowrap hover:underline focus:underline focus-visible:ring-2"
                  :to="{ name: item.name }"
                  >{{ item.text }}</router-link
                >

                <cu-text-select-field
                  slot="search"
                  id="123"
                  submittype="search"
                  placeholder="What would you like to learn?"
                  class="mr-6"
                  grey="true"
                  noBorder="true"
                  @receiveValue="(opt: any) => opt.detail.textValue.length >= 2 ? $router.push({ name: 'search-all', query: { query: opt.detail.textValue}}) : null"
                ></cu-text-select-field>
                <cu-buttons
                  slot="content-item"
                  :value="loginText"
                  :btntype="darkHeader ? 'secondary' : 'tertiary'"
                  size="sm"
                  data-cy="login-header-button"
                  @click="() => sendTelemetry(loginText)"
                ></cu-buttons>
                <cu-buttons
                  slot="content-item"
                  :value="joinCiscoText"
                  btntype="primary"
                  size="sm"
                  data-cy="join-header-button"
                  @click="() => sendTelemetry(joinCiscoText)"
                ></cu-buttons>
              </cu-top-header>
            </div>
          </header>

          <div class="relative">
            <main class="min-h-screen">
              <page-header
                v-if="showPageNav"
                :dark="dark"
                :scrolled="showProfile"
                :show-logo="showProfile"
                :searchQuery="query"
                instance="app-not-header"
                @update-query="updateQuery"
              />
              <h1 class="sr-only" v-if="pageHeading.length">
                {{ pageHeading }}
              </h1>
              <component :is="Component" />

              <div
                class="container fixed left-[50%] top-0 z-[1200] translate-x-[-50%] pb-6 text-22 font-light text-white-lightest lg:flex lg:px-4"
              >
                <profile-list
                  v-if="showDropDown && !isUnauth()"
                  @showGlobalSuccess="showGlobalSuccess = true"
                  :show-dropdown="showDropDown"
                />
              </div>
              <personal-interest-survey
                v-if="!isUnauth()"
                :dark="dark"
                :show="show"
                @closePersonalInterestSurvey="closeSurvey"
              />
              <site-wide-message
                :showModal="showGlobalSuccess"
                @hideGlobalSuccess="showGlobalSuccess = false"
              />
            </main>
            <cu-footer
              data-cy="page-footer"
              :_viewPlansLinks="VPfooterInfo"
              :_communityLinks="Communityfooter"
              @cu-footer-link="handleFooterLink"
            />
            <div v-if="$route.name !== 'Path'" class="larger-only">
              <dui-floatingbutton
                position="middle right shakeGesture"
                :url="returnFeedbackUrl()"
                buttontext="Feedback"
              />
            </div>
          </div>
        </div>
      </transition>
      <div @modalResponse="openCartModal = false">
        <cart-modal
          v-if="openCartModal"
          :content="contentData"
          :singleTitleBundles="singleTitleBundles"
          :course-card="courseCard"
          :essentials-bundle="essentialsBundle"
          :all-access-bundle="allAccessBundle"
          :user-status="userStatus"
          :essentials-id="essentialsId"
        />
      </div>
    </router-view>
  </div>
</template>
