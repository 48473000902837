import { isXylemeSyndicateUrl } from "@/utils/validateUrl";

export default function (url: string, returnText: string) {
  const returnUrl = window.location.href;
  const returnToText = `Return to ${returnText}`;
  let rtn;
  if (url && isXylemeSyndicateUrl(url)) {
    rtn = url;
  } else {
    rtn = `${url}?return_text=${encodeURIComponent(
      returnToText
    )}&return_url=${encodeURIComponent(returnUrl)}`;
  }
  return rtn;
}
