import { z } from "zod";

const contentTypes = z.string().optional();

const levels = z
  .enum(["Beginner", "Intermediate", "Advanced", "Expert", ""])
  .catch((ctx) => {
    if (import.meta.env.DEV) {
      console.log(ctx.error);
    }
    return "";
  });

const publishers = z
  .enum([
    "Cisco",
    "AWS",
    "Microsoft",
    "Skillsoft",
    "Syndicate",
    "SkillSoft",
    "",
  ])
  // eslint-disable-next-line sonarjs/no-identical-functions
  .catch((ctx) => {
    if (import.meta.env.DEV) {
      console.log(ctx.error);
    }
    return "";
  });

const certificationLevels = z
  .enum(["Entry", "Associate", "Professional", "Expert", ""])
  // eslint-disable-next-line sonarjs/no-identical-functions
  .catch((ctx) => {
    if (import.meta.env.DEV) {
      console.log(ctx.error);
    }
    return "";
  });

const searchFacets = z
  .object({
    type: z
      .array(
        z.object({
          name: z.enum(["01_content_type"]),
          type: z.enum(["value"]),
          data: z
            .array(
              z.object({
                count: z.number().optional(),
                value: contentTypes.optional(),
              })
            )
            .optional(),
        })
      )
      .max(1)
      .optional(),
    job_roles: z
      .array(
        z.object({
          name: z.enum(["03_job_roles"]),
          type: z.enum(["value"]),
          data: z
            .array(
              z.object({
                count: z.number().optional(),
                value: contentTypes.optional(),
              })
            )
            .optional(),
        })
      )
      .max(1)
      .optional(),
    difficulty_type: z
      .array(
        z.object({
          name: z.enum(["03_levels"]),
          type: z.enum(["value"]),
          data: z
            .array(
              z.object({
                count: z.number().optional(),
                value: levels.optional(),
              })
            )
            .optional(),
        })
      )
      .max(1)
      .optional(),
    certification_level: z
      .array(
        z.object({
          name: z.enum(["10_certification_level"]),
          type: z.enum(["value"]),
          data: z
            .array(
              z.object({
                count: z.number().optional(),
                value: certificationLevels.optional(),
              })
            )
            .optional(),
        })
      )
      .max(1)
      .optional(),
    content_bundle: z
      .array(
        z.object({
          name: z.enum(["09_content_bundle"]),
          type: z.enum(["value"]),
          data: z
            .array(
              z.object({
                count: z.number().optional(),
                value: z.number().optional(),
              })
            )
            .optional(),
        })
      )
      .max(1)
      .optional(),
    technology: z
      .array(
        z.object({
          name: z.enum(["02_technology"]),
          type: z.enum(["value"]),
          data: z
            .array(
              z.object({
                count: z.number().optional(),
                value: z.string().optional(),
              })
            )
            .optional(),
        })
      )
      .max(1)
      .optional(),
    publisher_name: z
      .array(
        z.object({
          name: z.enum(["04_publisher"]),
          type: z.enum(["value"]),
          data: z
            .array(
              z.object({
                count: z.number().optional(),
                value: publishers.optional(),
              })
            )
            .optional(),
        })
      )
      .max(1)
      .optional(),
    number_of_labs: z
      .array(
        z
          .object({
            name: z.enum(["05_number_of_labs"]),
            type: z.enum(["range"]),
            data: z.array(
              z
                .object({
                  from: z.number().optional(),
                  count: z.number().optional(),
                })
                .optional()
            ),
          })
          .optional()
      )
      .max(1)
      .optional(),
    number_of_assessments: z
      .array(
        z
          .object({
            name: z.enum(["06_number_of_assessments"]),
            type: z.enum(["range"]),
            data: z.array(
              z
                .object({
                  from: z.number().optional(),
                  count: z.number().optional(),
                })
                .optional()
            ),
          })
          .optional()
      )
      .max(1)
      .optional(),
    number_of_credits: z
      .array(
        z
          .object({
            name: z.enum(["07_number_of_credits"]),
            type: z.enum(["range"]),
            data: z.array(
              z
                .object({
                  count: z.number().optional(),
                  name: z.string().optional(),
                  from: z.number().optional(),
                  to: z.number().optional(),
                })
                .optional()
            ),
          })
          .optional()
      )
      .max(1)
      .optional(),
    event_date: z
      .array(
        z
          .object({
            name: z.enum(["08_event_date"]),
            type: z.enum(["range"]),
            data: z.array(
              z
                .object({
                  from: z.string().optional(),
                  to: z.string().optional(),
                  count: z.number().optional(),
                })
                .optional()
            ),
          })
          .optional()
      )
      .max(1)
      .optional(),
  })
  .optional();

const metaInfo = z
  .object({
    engine: z.object({
      name: z.string(),
      type: z.string(),
    }),
    page: z.object({
      current: z.number(),
      size: z.number(),
      total_pages: z.number(),
      total_results: z.number(),
    }),
    request_id: z.string().optional(),
    precision: z.number(),
  })
  .optional();

const searchResult = z
  .object({
    active: z.object({
      raw: z.number().min(1).max(1),
    }),
    author: z.object({
      raw: z.string().default("").nullish(),
    }),
    content_bundle: z.object({
      raw: z.array(z.number().optional()).nullable(),
    }),
    content_count: z.object({
      raw: z.number().nonnegative().optional(),
    }),
    created_at: z.object({
      raw: z.string().default("").or(z.date()),
    }),
    description: z.object({
      raw: z.string().max(3000).min(0),
      snippet: z.string().optional(),
    }),
    difficulty_type: z.object({
      raw: levels.nullish(),
    }),
    certification_level: z.object({
      raw: z.array(certificationLevels).nullable(),
    }),
    doc_id: z.object({
      raw: z.number().default(0).nullish(),
    }),
    duration: z.object({
      raw: z.number().default(0).nullish(),
    }),
    duration_sec: z.object({
      raw: z.number().nonnegative().optional(),
    }),
    event_date: z.object({
      raw: z.date().or(z.string().default("")),
    }),
    free: z.object({
      raw: z.string().default("").nullish(),
    }),
    guid: z.object({
      raw: z.string().default("").nullish(),
    }),
    job_roles: z.object({
      raw: z.string().default("").nullish(),
    }),
    is_partial: z
      .object({
        raw: z.any().optional(),
      })
      .optional(),
    keywords: z.object({
      raw: z.string().array().default([""]).nullish(),
    }),
    number_of_labs: z.object({
      raw: z.number().nonnegative().optional(),
    }),
    number_of_credits: z.object({
      raw: z.number().nonnegative().optional(),
    }),
    _meta: z.object({
      raw: z.number().nonnegative().optional(),
    }),
    meta_guids: z.object({
      raw: z.string().default("").nullish(),
    }),
    number_of_assessments: z.object({
      raw: z.number().nonnegative().optional(),
    }),
    parents: z.object({
      raw: z.string().default("").nullish(),
    }),
    publisher_name: z
      .object({
        raw: publishers.optional(),
      })
      .optional(),
    skills_associated: z
      .object({
        raw: z.array(z.string()).nullish().or(z.string().default("").nullish()),
      })
      .optional(),
    solution: z.object({
      raw: z.string().default("").nullish(),
    }),
    summary: z
      .object({
        raw: z.string().min(0).max(2000).optional(),
      })
      .optional(),
    tags: z.object({
      raw: z.string(),
    }),
    technology: z.object({
      raw: z.array(z.string().nullish()).nullable(),
    }),
    tenant: z
      .object({
        raw: z.array(z.enum(["apollo", "", "vulcan", "apollo-alpha"])),
      })
      .optional(),
    track_ids: z.object({
      raw: z.array(z.number().optional()).nullish(),
    }),
    title: z.object({
      raw: z.string().max(250).min(0).optional(),
      snippet: z.string().max(250).min(0).optional(),
    }),
    type: z.object({
      raw: contentTypes,
    }),
    url: z.object({
      raw: z.string().default("").nullish(),
      snippet: z.string().optional(),
    }),
  })
  .passthrough();

export const searchResponseBody = z.object({
  facets: searchFacets,
  meta: metaInfo,
  results: z.array(searchResult.optional()).nullish(),
});

export const searchRequest = z.object({
  query: z.string(),
  page: z
    .object({
      current: z.number(),
      size: z.number(),
    })
    .optional(),
  tenant: z.array(z.string()).optional(),
  analytics_tags: z.array(z.string()).optional(),
  sort: z.string().optional(),
  to: z.string().optional().or(z.array(z.string())),
  type: z.string().optional(),
  facet_filters: z
    .array(
      z.object({
        name: z.string(),
        keys: z.array(z.string().or(z.number())),
      })
    )
    .optional(),
  explore: z.boolean().optional(),
  url: z.string().optional(),
});

const searchCertResult = z
  .object({
    active: z.object({
      raw: z.number().min(1).max(1),
    }),
    author: z.object({
      raw: z.string().default("").nullish(),
    }),
    content_bundle: z.object({
      raw: z.array(z.number().optional()).nullable(),
    }),
    content_count: z.object({
      raw: z.number().nonnegative().optional(),
    }),
    created_at: z.object({
      raw: z.string().default("").or(z.date()),
    }),
    description: z.object({
      raw: z.string().max(3000).min(0).optional(),
      snippet: z.string().optional(),
    }),
    certification_level: z.object({
      raw: certificationLevels,
    }),
    doc_id: z.object({
      raw: z.number().default(0).nullish(),
    }),
    duration: z.object({
      raw: z.number().default(0).nullish(),
    }),
    duration_sec: z.object({
      raw: z.number().nonnegative().optional(),
    }),
    event_date: z.object({
      raw: z.date().or(z.string().default("")),
    }),
    free: z.object({
      raw: z.string().default("").nullish(),
    }),
    guid: z.object({
      raw: z.string().default("").nullish(),
    }),
    job_roles: z.object({
      raw: z.array(z.string().default("").nullish()).optional(),
    }),
    keywords: z.object({
      raw: z.string().default("").nullish(),
    }),
    number_of_labs: z.object({
      raw: z.number().nonnegative().optional(),
    }),
    number_of_credits: z.object({
      raw: z.number().nonnegative().optional(),
    }),
    _meta: z.object({
      raw: z.number().nonnegative().optional(),
    }),
    meta_guids: z.object({
      raw: z.string().default("").nullish(),
    }),
    number_of_assessments: z.object({
      raw: z.number().nonnegative().optional(),
    }),
    parents: z.object({
      raw: z.string().default("").nullish(),
    }),
    publisher_name: z
      .object({
        raw: publishers.optional(),
      })
      .optional(),
    skills_associated: z
      .object({
        raw: z.array(z.string()).nullish().or(z.string().default("").nullish()),
      })
      .optional(),
    solution: z.object({
      raw: z.string().default("").nullish(),
    }),
    summary: z
      .object({
        raw: z.string().min(0).max(2000).optional(),
      })
      .optional(),
    tags: z.object({
      raw: z.string(),
    }),
    technology: z.object({
      raw: z.array(z.string().nullish()).nullable(),
    }),
    track_ids: z.object({
      raw: z.array(z.number().optional()).nullish(),
    }),
    name: z.object({
      raw: z.string().max(250).min(0).optional(),
      snippet: z.string().max(250).min(0).optional(),
    }),
    type: z.object({
      raw: contentTypes,
    }),
    url: z.object({
      raw: z.string().default("").nullish(),
      snippet: z.string().optional(),
    }),
  })
  .passthrough();

export const searchCertResponseBody = z
  .object({
    facets: searchFacets,
    meta: metaInfo,
    results: z.array(searchCertResult.optional()).nullish(),
  })
  .passthrough();

export type SearchCertResponse = z.infer<typeof searchCertResponseBody>;
export type SearchResponse = z.infer<typeof searchResponseBody>;
export type SearchRequest = z.infer<typeof searchRequest>;
export type SearchResults = z.infer<typeof searchResult>;
export type SearchFacets = z.infer<typeof searchFacets>;
