import * as certifications from "@/services/catalogService/certifications";
import * as contentBundle from "@/services/catalogService/contentBundle";
import * as courses from "@/services/catalogService/courses";
import * as paths from "@/services/catalogService/paths";
import * as tracks from "@/services/catalogService/tracks";
export default {
  courses,
  paths,
  tracks,
  contentBundle,
  certifications,
};
