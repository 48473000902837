import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat.js";
import duration from "dayjs/plugin/duration.js";
import relativeTime from "dayjs/plugin/relativeTime.js";

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);

export default {
  // month and year in short format, e.g. Jan 2021
  shortFmt: (result: string): string =>
    dayjs(result).isValid() ? dayjs(result).format("MMM") : "",

  // month and year in long format, e.g. January 2021
  monthAndYear: (result: string): string =>
    dayjs(result).isValid() ? dayjs(result).format("MM/YYYY") : "",

  // day, month, and year in long format, e.g. 24 December 2020
  dayMonthYear: (result: string): string =>
    dayjs(result).isValid() ? dayjs(result).format("DD MMM YYYY") : "",

  // day in short format, e.g. 24
  dayFmt: (result: string): string =>
    dayjs(result).isValid() ? dayjs(result).format("DD") : "",

  // day, month, and year in long format, e.g. December 24, 2020
  longDayFmt: (result: string): string =>
    dayjs(result).isValid() ? dayjs(result).format("MMM D, YYYY") : "",

  // month and day in long format, e.g. December 24, 2020
  longMonthFmt: (result: string): string =>
    dayjs(result).isValid() ? dayjs(result).format("MMMM D, YYYY") : "",

  // month and day in long format, e.g. December 24, 2020
  longMonthFmtUtc: (result: string): string =>
    dayjs(result).isValid() ? dayjs(result).utc().format("MMMM D, YYYY") : "",

  // year, month and day in long format, e.g. 01/01/2024
  longDayMonthYearFmt: (result: string): string =>
    dayjs(result).isValid() ? dayjs(result).format("MM/DD/YYYY") : "",

  // month and day in long format, e.g. December 24th, 2020
  longMonthOrdFmt: (result: string): string =>
    dayjs(result).isValid() ? dayjs(result).format("MMMM Do, YYYY") : "",

  // month and year in format, e.g. Sept, 2023
  monthYearComma: (result: string): string =>
    dayjs(result).isValid() ? dayjs(result).format("MMM, YYYY") : "",

  // month, date and year in format, e.g. Aug. 4, 2024
  monthDotDateCommaYear: (result: string): string =>
    dayjs(result).isValid() ? dayjs(result).format("MMM. D, YYYY") : "",
};
