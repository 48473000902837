import http from "@/services/http";

type BillingAddress = {
  firstName: string;
  lastName: string;
  email: string;
  address1: string;
  address2: string;
  country: string;
  city: string;
  state: string;
  zipCode: string;
};

export async function add(basketId: string, billingAddress: BillingAddress) {
  const { data } = await http().post(
    window.env.COMMERCE_API + "/commerce/basket/addresses",
    {
      basket: {
        basketId: basketId,
      },
      shippingInfo: {
        shippingMethod: {
          id: "cisco",
        },
        shippingAddress: {
          firstName: billingAddress.firstName,
          lastName: billingAddress.lastName,
          address1:
            billingAddress.address1 +
            (billingAddress.address2 != null ? "" : billingAddress.address2),
          city: billingAddress.city,
          countryCode: billingAddress.country,
          stateCode: billingAddress.state,
          postalCode: billingAddress.zipCode,
        },
      },
      billingInfo: {
        firstName: billingAddress.firstName,
        lastName: billingAddress.lastName,
        address1:
          billingAddress.address1 +
          (billingAddress.address2 != null ? "" : billingAddress.address2),
        city: billingAddress.city,
        countryCode: billingAddress.country,
        stateCode: billingAddress.state,
        postalCode: billingAddress.zipCode,
      },
    }
  );
  return data;
}

export async function updateCustomer(basketId: string, customer: any) {
  const { data } = await http().post(
    window.env.COMMERCE_API + "/commerce/basket/attributes",
    {
      basket: {
        basketId: basketId,
      },
      customAttributes: customer,
    }
  );
  return data;
}
