import type { LocationQuery } from "vue-router";

import http from "@/services/http";
import {
  setFacetFilters,
  setPostPage,
  setTenantAnalyticsUrl,
  setType,
} from "@/services/searchService/common";
import {
  SearchRequest,
  searchRequest,
  searchResponseBody,
} from "@/services/searchService/types";
import unauthenticatedHttp from "@/services/unauthenticatedHttp";
import { buildFacetsFiltersFromUrlParms } from "@/views/Search/filtersHelper";

// service handler
export async function fetchTypeResults(
  postObj: SearchRequest,
  query: LocationQuery,
  unauthorized: boolean = false
) {
  if (!postObj.type) return;
  postObj = setType(postObj);

  postObj = setPostPage(postObj);

  const facetFilters = buildFacetsFiltersFromUrlParms(query);
  if (facetFilters) {
    postObj = setFacetFilters(postObj, facetFilters);
  }
  // tag with tenant, analytics, and the url
  postObj = setTenantAnalyticsUrl(postObj);

  // check if the object is valid
  searchRequest.parse(postObj);

  if (unauthorized) {
    // request and await response data
    const { data } = await unauthenticatedHttp().post(
      window.env.UNAUTH_API + "/search-type-unauth",
      postObj
    );
    // check if response is valid
    return searchResponseBody.parse(data["search-type"]);
  }

  // request and await response data
  const { data } = await http().post(
    window.env.SEARCH_API_LOCATION + "/search-type",
    postObj
  );
  // check if response is valid
  return searchResponseBody.parse(data);
}

export async function fetchFacets(
  postObj: SearchRequest,
  query: LocationQuery,
  unauthorized: boolean = false
) {
  const facetFilters = buildFacetsFiltersFromUrlParms(query);

  postObj = setPostPage(postObj);

  if (facetFilters) {
    postObj = setFacetFilters(postObj, facetFilters);
  }
  // tag with tenant, analytics, and the url
  postObj = setTenantAnalyticsUrl(postObj);

  // check if the object is valid
  searchRequest.parse(postObj);

  if (unauthorized) {
    // request and await response data
    const { data } = await unauthenticatedHttp().post(
      window.env.UNAUTH_API + "/search-facets-unauth",
      postObj
    );
    // check if response is valid
    return searchResponseBody.passthrough().parse(data["search-facets"]);
  }

  // request and await response data
  const { data } = await http().post(
    window.env.SEARCH_API_LOCATION + "/search-facets",
    postObj
  );
  // check if response is valid
  return searchResponseBody.passthrough().parse(data);
}
