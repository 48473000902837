<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";
export default defineComponent({
  props: {
    showPic: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    profileDropdown: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showThemeSelect: false,
      theme: "light",
      showDropDown: false,
      surveyId: 0,
    };
  },
  computed: {
    ...mapGetters({
      userStatus: "user/status",
    }),
    initials() {
      return this.userStatus ? this.userStatus.userInitials : "";
    },
  },
  methods: {
    ...mapMutations({
      toggleDropDown: "user/toggleDropDown",
    }),
  },
});
</script>

<template>
  <div class="flex justify-end text-left">
    <button
      tabindex="0"
      :data-cy="!dark ? profileDropdown : `${profileDropdown}-dark`"
      class="w-8 cursor-pointer rounded-b-full bg-blueAlt-lightest py-2 text-center text-18 text-blue hover:text-blue-lightest focus:outline focus:outline-2 md:w-11 md:py-4"
      @click="toggleDropDown"
      @keyup.space="toggleDropDown"
      @keyup.enter="toggleDropDown"
    >
      <span class="sr-only">Toggle Profile Menu</span>
      <span v-if="showPic && initials" class="sr-hidden">
        {{ initials }}
      </span>
    </button>
  </div>
</template>
