import { uniq } from "lodash-es";
import type { LocationQuery } from "vue-router";

import type { FacetFilter, UrlParms } from "@/views/Search/SearchTypes";

function filterPathFacets(target: string, filters: Array<FacetFilter>) {
  const targetFilter = filters.filter((facet) => facet.name == "type");
  const rtn: any = [];
  if (targetFilter.length) {
    targetFilter.forEach((element) => {
      rtn.push(element.keys[0].toString());
    });
    return rtn;
  }
  return null;
}
function filterFacets(target: string, filters: Array<FacetFilter>) {
  const targetParm = filters.filter((facet) => facet.name == target);
  if (targetParm?.[0]) {
    return [JSON.parse(JSON.stringify(targetParm[0]?.keys))].flat();
  }
  return null;
}
function coerceToArray(levels: any) {
  let levelsParm = levels;
  if (typeof levelsParm == "string") {
    levelsParm = levelsParm.split(",");
  }
  return uniq([levelsParm].flat());
}

export function buildUrlParmsfromFacetFilters(filters: Array<FacetFilter>) {
  const urlParms = {} as UrlParms;

  const labs = filterPathFacets("number_of_labs", filters);
  const assessments = filterPathFacets("number_of_assessments", filters);
  const credits = filterFacets("number_of_credits", filters);
  const techParm = filterFacets("technology", filters);
  const levelsParm = filterFacets("levels", filters);
  const publisherParm = filterFacets("publisher", filters);
  const contentBundles = filterFacets("content_bundle", filters);
  const certLevelParm = filterFacets("certification_level", filters);
  const jobRoles = filterFacets("job_roles", filters);

  const type = [];
  if (labs?.includes("labs")) {
    type.push("labs");
  }
  if (assessments?.includes("assessments")) {
    type.push("assessments");
  }
  if (credits) {
    urlParms.number_of_credits = uniq(credits);
  }
  if (type.length) {
    urlParms.type = type;
  }

  if (certLevelParm) {
    urlParms.certification_level = uniq(certLevelParm);
  }

  if (jobRoles) {
    urlParms.job_roles = uniq(jobRoles);
  }

  if (techParm) {
    urlParms.technology = uniq(techParm);
  }

  if (levelsParm) {
    urlParms.levels = uniq(levelsParm);
  }

  if (publisherParm) {
    urlParms.publisher = uniq(publisherParm);
  }

  if (contentBundles) {
    urlParms.subscription = uniq(contentBundles);
  }

  return urlParms;
}

export function buildFacetsFiltersFromUrlParms(routeQueryParms: LocationQuery) {
  const facetFilters = [] as Array<FacetFilter>;
  if (!routeQueryParms) {
    return facetFilters;
  }

  if (routeQueryParms.type?.length) {
    const queryTypes = coerceToArray(routeQueryParms.type);

    queryTypes?.forEach((queryType: string) => {
      facetFilters.push({
        name: `number_of_${queryType}`,
        keys: [],
      });
    });
  }
  if (routeQueryParms.certification_level?.length) {
    const targetParm = coerceToArray(routeQueryParms.certification_level);

    facetFilters.push({
      name: "certification_level",
      keys: targetParm,
    });
  }

  if (routeQueryParms.technology?.length) {
    const targetParm = coerceToArray(routeQueryParms.technology);

    facetFilters.push({
      name: "technology",
      keys: targetParm,
    });
  }

  if (routeQueryParms.number_of_credits?.length) {
    const targetParm = coerceToArray(routeQueryParms.number_of_credits);

    facetFilters.push({
      name: "number_of_credits",
      keys: targetParm,
    });
  }

  if (routeQueryParms.levels?.length) {
    const targetParm = coerceToArray(routeQueryParms.levels);

    facetFilters.push({
      name: "levels",
      keys: targetParm,
    });
  }

  if (routeQueryParms.publisher?.length) {
    const targetParm = coerceToArray(routeQueryParms.publisher);

    facetFilters.push({
      name: "publisher",
      keys: targetParm,
    });
  }

  if (routeQueryParms.job_roles?.length) {
    const targetParm = coerceToArray(routeQueryParms.job_roles);

    facetFilters.push({
      name: "job_roles",
      keys: targetParm,
    });
  }

  if (routeQueryParms.subscription?.length) {
    const targetParm = coerceToArray(routeQueryParms.subscription);

    const parsedSubscriptionInts = targetParm.map((subscription: string) =>
      parseInt(subscription, 10)
    );
    if (parsedSubscriptionInts.length) {
      facetFilters.push({
        name: "content_bundle",
        keys: parsedSubscriptionInts,
      });
    }
  }

  return facetFilters;
}
