import * as assessments from "@/services/middlewareService/assessments";
import * as Communities from "@/services/middlewareService/communities";
import * as DiscussionThreads from "@/services/middlewareService/discussionThreads";
import * as Marquees from "@/services/middlewareService/marquees";
import * as MyCommunities from "@/services/middlewareService/myCommunities";
import * as practiceExamsScore from "@/services/middlewareService/practiceExamsScore";
import * as ProductService from "@/services/middlewareService/productService";
import * as Recommendations from "@/services/middlewareService/recommendations";

export default {
  Communities,
  DiscussionThreads,
  MyCommunities,
  ProductService,
  Recommendations,
  Marquees,
  practiceExamsScore,
  assessments,
};
