import { z } from "zod";

import http from "@/services/http";

const globalProduct = z
  .object({
    id: z.number(),
    content_bundle_id: z.number(),
    global_product_id: z.number(),
    global_product_name: z.string().nullish(),
    updated_at: z.string().datetime().nullish(),
    created_at: z.string().datetime().nullish(),
  })
  .passthrough()
  .nullish();

const courseObject = z
  .object({
    id: z.number(),
    guid: z.string(),
    title: z.string(),
    active: z.number(),
    adjacent_technology: z.string().nullish(),
    author: z.string().nullish(),
    content_provider: z.string().nullish(),
    content_type_id: z.number(),
    copyright_holder: z.string().nullish(),
    copyright_year: z.number().nullish(),
    data_classification: z.string().nullish(),
    is_partial: z.number().nullish(),
    is_private: z.number().nullish(),
    sort_order: z.number().nullish(),
    duration_sec: z.number().nullish(),
    description: z.string(),
    type: z.string(),
    content_count: z.number().nullish(),
    difficulty_type_id: z.number().nullish(),
    difficulty_type: z.string().nullish(),
    publisher_id: z.number(),
    publisher_name: z.string(),
    product_alias: z.string().nullish(),
    product_version: z.string().nullish(),
    presenter: z.string().nullish(),
    solutions: z.array(z.any()).nullish(),
    created_at: z.string().datetime().nullish(),
    updated_at: z.string().datetime().nullish(),
    deleted_at: z.string().datetime().nullish(),
    url: z.string().nullish(),
  })
  .passthrough()
  .nullish();

const trackObject = z
  .object({
    id: z.number(),
    guid: z.string(),
    name: z.string(),
    description: z.string(),
    summary: z.string(),
    active: z.number(),
    is_partial: z.number(),
    sort_order: z.number().nullish(),
    duration_sec: z.number(),
    type: z.string(),
    number_of_labs: z.number().nullish(),
    number_of_assessments: z.number().nullish(),
    number_of_credits: z.number().nullish(),
    content_count: z.number().nullish(),
    difficulty_type_id: z.number().nullish(),
    difficulty_type: z.string(),
    technology: z.string(),
    is_private: z.number().nullish(),
    solutions: z.array(z.any()).nullish(),
    created_at: z.string().datetime().nullish(),
    updated_at: z.string().datetime().nullish(),
    deleted_at: z.string().datetime().nullish(),
    Courses: z.array(courseObject),
  })
  .passthrough()
  .nullish();

const pathResponse = z
  .object({
    id: z.number(),
    guid: z.string(),
    name: z.string(),
    acronym: z.string().nullish(),
    version: z.number().nullish(),
    display_version: z.string().nullish(),
    description: z.string(),
    summary: z.string(),
    active: z.number(),
    is_partial: z.number().nullish(),
    sort_order: z.number().nullish(),
    duration_sec: z.number(),
    type: z.string(),
    number_of_labs: z.number().nullish(),
    number_of_assessments: z.number().nullish(),
    number_of_credits: z.number().nullish(),
    content_count: z.number().nullish(),
    difficulty_type_id: z.number(),
    difficulty_type: z.string(),
    technology: z.string(),
    publisher_id: z.number().nullish(),
    publisher_name: z.string(),
    is_private: z.number().nullish(),
    certifications: z.array(z.string()).nullish(),
    skills_associated: z
      .array(z.string())
      .nullish()
      .or(z.string().default("").nullish()),
    global_products: z.array(globalProduct).nullish(),
    solutions: z.array(z.any()).nullish(),
    created_at: z.string().datetime().nullish(),
    updated_at: z.string().datetime().nullish(),
    deleted_at: z.string().datetime().nullish(),
    tracks: z.array(trackObject).nullish(),
  })
  .passthrough()
  .nullish();

const dataResponse = z
  .object({
    data: z.array(pathResponse),
    metadata: z.object({
      current_page: z.number(),
      total_pages: z.number(),
      total_count: z.number(),
      pageSize: z.number(),
      previous_page_url: z.string().nullish(),
      next_page_url: z.string().nullish(),
    }),
    total_records: z.number(),
    timestamp: z.string().datetime(),
    version: z.string(),
  })
  .passthrough();

export async function fetchWithChildren(pathId: string) {
  const { data } = await http().get(
    `${window.env.CATALOG_API_LOCATION}/retrievePaths/${pathId}?includeChild=true`
  );
  // return data;
  return pathResponse.parse(data);
}

export async function carousel() {
  const { data } = await http().get(
    `${window.env.CATALOG_API_LOCATION}/retrievePaths/?includeChild=false&length=18`
  );

  return dataResponse.parse(data).data;
}

export async function pathFetch() {
  const { data } = await http().get(
    `${window.env.CATALOG_API_LOCATION}/retrievePaths/`
  );

  return dataResponse.parse(data).data;
}

export async function retrievePathById(id: number) {
  if (id != 0) {
    const { data } = await http().get(
      `${window.env.CATALOG_API_LOCATION}/retrievePaths/${id}`
    );
    return pathResponse.parse(data);
  }
}
export async function retrievePathByGuid(guid: string) {
  if (guid != "") {
    const { data } = await http().get(
      `${window.env.CATALOG_API_LOCATION}/retrievePaths?guid=${guid}`
    );
    return dataResponse.parse(data).data;
  }
}
