<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import catalogService from "@/services/catalogService";
import { fetchById } from "@/services/catalogService/certifications";
import middlewareService from "@/services/middlewareService";
import { certificationRecommendedPaths } from "@/services/middlewareService/recommendations";
import CertificationHeader from "@/views/Certifications/assets/CertificationHeader.vue";
import CertificationOverview from "@/views/Certifications/assets/CertificationOverview.vue";
import ExploreRecommendedResources from "@/views/Certifications/assets/ExploreRecommendedResources.vue";
import ExploreResources from "@/views/Certifications/assets/ExploreResources.vue";
import HowDoEarn from "@/views/Certifications/assets/HowDoEarn.vue";
import ltSideShape from "@/views/Certifications/assets/images/lt-side-shape.png?url";
import rtSideShape from "@/views/Certifications/assets/images/rt-side-shape.png?url";
import PrepareAssociate from "@/views/Certifications/assets/PrepareAssociate.vue";
import PrepareEntry from "@/views/Certifications/assets/PrepareEntry.vue";
import PrepareExpert from "@/views/Certifications/assets/PrepareExpert.vue";
import PrepareProfessional from "@/views/Certifications/assets/PrepareProfessional.vue";
import ScheduleEntryAssociate from "@/views/Certifications/assets/ScheduleEntryAssociate.vue";
import ScheduleExpert from "@/views/Certifications/assets/ScheduleExpert.vue";
import ScheduleProfessional from "@/views/Certifications/assets/ScheduleProfessional.vue";
import communityResult from "@/views/CommunityLanding/communityResult.vue";

interface FillExamCardOptions {
  guids: string[];
  acronym: string;
  duration_sec: number;
  exam_number: string;
  title: string;
  type: string;
  prescribed_prep_material: any[];
  topics: any[];
  practiceExam: any;
}

export default defineComponent({
  components: {
    communityResult,
    CertificationHeader,
    PrepareAssociate,
    PrepareEntry,
    PrepareExpert,
    PrepareProfessional,
    HowDoEarn,
    CertificationOverview,
    ScheduleEntryAssociate,
    ScheduleProfessional,
    ScheduleExpert,
    ExploreResources,
    ExploreRecommendedResources,
  },
  props: {
    viewAllTag: {
      type: String,
      default: "",
    },
    viewAllLink: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      status: "user/status",
    }),
    showRecommendations() {
      return window.env.SHOW_RECOMMENDATIONS;
    },
  },
  data() {
    return {
      technologies: [] as string[],
      topics: [] as string[],
      exCardEntry: {
        title: "",
        acronym: "",
        guid: [] as Array<string>,
        duration_sec: 0,
        exam_number: "",
        type: "",
        prescribed_prep_material: {} as any,
        practiceExam: null as any,
      },
      exCardCore: {
        title: "",
        acronym: "",
        guid: [] as Array<string>,
        duration_sec: 0,
        exam_number: "",
        type: "",
        prescribed_prep_material: {} as any,
        practiceExam: null as any,
      },
      exCardConcentration: {
        title: "",
        acronym: "",
        guid: [] as Array<string>,
        duration_sec: 0,
        exam_number: "",
        type: "",
        prescribed_prep_material: {} as any,
        practiceExam: null as any,
      },
      exCardLab: {
        title: "",
        acronym: "",
        guid: [] as Array<string>,
        duration_sec: 0,
        exam_number: "",
        type: "",
        prescribed_prep_material: {} as any,
        practiceExam: null as any,
      },
      recommendedPaths: [] as any,
      pathConc: [] as Array<any>,
      currentExam: Object as any,
      currentGuids: [] as string[],
      certification: {} as any,
      rtSideShape: rtSideShape,
      ltSideShape: ltSideShape,
      communities: {} as any,
      fetched: false,
      catalogData: null as any,
      show_concentration: true,
      title: "" as string,
      practiceExamGuids: [] as string[],
      practiceExamVal: "practice-exam" as string,
    };
  },
  methods: {
    fetchRecommended(certPreference: string) {
      middlewareService.Recommendations.getTopOfFunnelRecommendations(
        certPreference
      )
        .then((data) => {
          this.catalogData = data;
        })
        .catch(() => {
          // do nothing
        });
    },

    fetchCommunityData() {
      middlewareService.Communities.fetch()
        .then((data) => {
          if (data.communities) {
            data.communities.forEach((community: any) => {
              if (community.Id == this.certification.community_id) {
                this.communities = community;
              }
            });
          }
          this.fetched = true;
        })
        .catch(() => {
          // do nothing
        });
    },

    showConcentration(item: any) {
      if (item.type == "concentration") {
        this.currentExam = item;
        this.fillExamCard({
          guids: this.currentGuids,
          acronym: this.currentExam.acronym,
          duration_sec: this.currentExam.duration_sec,
          exam_number: this.currentExam.exam_number,
          title: this.currentExam.title,
          type: this.currentExam.type,
          prescribed_prep_material: this.currentExam.prescribed_prep_material,
          topics: this.currentExam.topics,
          practiceExam: this.currentExam.practiceExam,
        });
        item.prescribed_prep_material?.forEach((material: any) => {
          if (material.type == "path") {
            this.pathConc = this.recommendedPaths.find(
              (path: any) => path.guid == material.guid
            );
          }
        });
      }

      if (item.prescribed_prep_material) {
        item.prescribed_prep_material.forEach((guid: any) => {
          this.currentGuids.push(guid.guid);
        });
      }
    },

    fillExamCard({
      guids,
      acronym,
      duration_sec,
      exam_number,
      title,
      type,
      prescribed_prep_material,
      topics,
    }: FillExamCardOptions) {
      let examData = {
        acronym: "",
        guid: [] as Array<string>,
        duration_sec: 0,
        exam_number: "",
        title: "",
        type: "",
        prescribed_prep_material: [] as Array<any>,
        topics: [] as Array<any>,
        practiceExam: null as any,
      };
      examData["guid"] = guids;
      examData["acronym"] = acronym;
      examData["duration_sec"] = duration_sec;
      examData["exam_number"] = exam_number;
      examData["title"] = title;
      examData["type"] = type;
      examData["prescribed_prep_material"] = prescribed_prep_material;
      examData["topics"] = topics;

      if (type == "core") {
        this.exCardCore = examData;
      } else if (type == "concentration") {
        this.exCardConcentration = examData;
      } else if (type == "lab") {
        this.exCardLab = examData;
      } else if (type == "entry") {
        this.exCardEntry = examData;
      }
    },

    async getCertification() {
      await fetchById(Number(this.$route.params.certificationId))
        .then((result: any) => {
          this.certification = result;
          if (!this.certification) return;

          if (this.showRecommendations) {
            this.fetchRecommended(this.certification.acronym);
          }
          this.technologies = this.certification?.technology
            ? this.certification?.technology
            : [];
          let pathGuids: string[] = [];
          this.certification.exam?.forEach((exam: any) => {
            exam.topics.forEach((topic: any) => {
              this.topics.push(topic.topic_name);
            });

            let guids = this.fillAllExamCards(exam);
            pathGuids = pathGuids.concat(guids);
          });
          this.getPaths(pathGuids);
          this.getPracticeExams(this.practiceExamGuids);

          this.topics = this.topics.slice(0, 5);
          this.fetchCommunityData();
        })
        .catch(() => {
          this.$router.push({ name: "certifications" });
        });
    },

    fillAllExamCards(exam: any): string[] {
      let guids = [] as string[];
      switch (exam.type) {
        case "core":
          exam.prescribed_prep_material?.forEach((material: any) => {
            guids.push(material.guid);
            if (material.type == this.practiceExamVal) {
              this.practiceExamGuids.push(material.guid);
            }
          });
          break;
        case "concentration":
          this.title =
            "Select and pass the " + exam.type + " exam of your choice";
          exam.prescribed_prep_material?.forEach((mat: any) => {
            guids.push(mat.guid);
            if (mat.type == this.practiceExamVal) {
              this.practiceExamGuids.push(mat.guid);
            }
          });
          if (Object.keys(this.currentExam).length === 0) {
            this.currentExam = exam;
            exam.prescribed_prep_material.forEach((material: any) => {
              this.currentGuids.push(material.guid);
            });
          }
          break;
        case "lab":
        case "entry":
          this.title = "Pass the " + exam.type + " exam";
          exam.prescribed_prep_material?.forEach((mater: any) => {
            guids.push(mater.guid);
            if (mater.type == this.practiceExamVal) {
              this.practiceExamGuids.push(mater.guid);
            }
          });
          break;
      }
      this.fillExamCard({
        guids: guids,
        acronym: exam.acronym,
        duration_sec: exam.duration_sec,
        exam_number: exam.exam_number,
        title: exam.title,
        type: exam.type,
        prescribed_prep_material: exam.prescribed_prep_material,
        topics: exam.topics,
        practiceExam: null,
      });
      return guids;
    },
    getPaths(pathGuids: string[]) {
      certificationRecommendedPaths(pathGuids)
        .then((data) => {
          this.recommendedPaths = data.Data;
          if (Object.keys(this.pathConc).length === 0) {
            this.showConcentration(this.currentExam);
          }
        })
        .catch(() => {
          // do nothing
        });
    },
    async getPracticeExams(practiceExamGuids: string[]) {
      await catalogService.courses
        .retrieveCatalogItems(practiceExamGuids, this.practiceExamVal)
        .then((data) => {
          data?.map((exam: any) => {
            if (exam.exam_number == this.exCardCore.exam_number) {
              this.exCardCore.practiceExam = exam;
            } else if (exam.exam_number == this.currentExam.exam_number) {
              this.currentExam.practiceExam = exam;
            } else if (exam.exam_number == this.exCardLab.exam_number) {
              this.exCardLab.practiceExam = exam;
            } else if (exam.exam_number == this.exCardEntry.exam_number) {
              this.exCardEntry.practiceExam = exam;
            }
          });
        })
        .catch(() => {
          // do nothing
        });
    },
  },
  mounted() {
    this.getCertification();
  },
});
</script>

<template>
  <div
    class="relative mt-12 bg-white-lightest pt-20 dark:bg-black-lightest lg:pl-[4.5rem] lg:pr-[1rem] lg:pt-36"
  >
    <div class="absolute right-0 top-[25.625rem]">
      <img :src="rtSideShape" alt="" class="w-28 lg:w-44" />
    </div>
    <div class="absolute left-0 top-[155rem] w-16 lg:top-[20] lg:h-56 lg:w-56">
      <img :src="ltSideShape" alt="" />
    </div>
    <div class="container mx-auto">
      <CertificationHeader
        root="Certifications"
        :location="certification.acronym"
        :level="certification.certification_level"
      />

      <CertificationOverview
        :certification="certification"
        :technologies="technologies"
        :title="title"
      />
      <HowDoEarn :certification="certification" />

      <prepare-associate
        v-if="status && certification.certification_level === 'Associate'"
        :exCardCore="exCardCore"
        :exCardEntry="exCardEntry"
        :status="status"
        :communities="communities"
        :recommendedPaths="recommendedPaths"
      />

      <prepare-entry
        v-else-if="status && certification.certification_level === 'Entry'"
        :exCardCore="exCardCore"
        :exCardEntry="exCardEntry"
        :status="status"
        :communities="communities"
        :recommendedPaths="recommendedPaths"
      />

      <prepare-expert
        v-else-if="status && certification.certification_level === 'Expert'"
        :exCardCore="exCardCore"
        :exCardEntry="exCardEntry"
        :exCardLab="exCardLab"
        :status="status"
        :communities="communities"
        :recommendedPaths="recommendedPaths"
      />

      <prepare-professional
        v-else-if="
          status && certification.certification_level === 'Professional'
        "
        :certification="certification"
        :exCardCore="exCardCore"
        :exCardEntry="exCardEntry"
        :exCardConcentration="exCardConcentration"
        :status="status"
        :communities="communities"
        :recommendedPaths="recommendedPaths"
        :current-exam="currentExam"
        :path-conc="pathConc"
        @show-concentration="showConcentration"
      />

      <div v-if="certification.community_id != ''" class="pb-4 lg:px-44">
        <div class="mt-5 text-[40px] text-black-light">
          <span id="connect" class="scroll-mt-[7rem]">Connect</span>
        </div>
        <p class="text-18">
          Join a community on the Cisco Learning Network to connect with
          certification experts and get learning guidance from your peers.
        </p>
        <div class="container mx-auto mt-10 md:px-4">
          <h2
            class="mb-4 text-[1.125rem] font-medium text-black-dark dark:text-white-dark"
          >
            Popular
            <div class="text-[1.125rem] font-bold md:inline-block">
              communities
            </div>
          </h2>
          <div class="flex-row gap-8 pt-4 lg:flex">
            <community-result
              v-if="communities"
              :key="communities.Name"
              :community="communities.Name"
              :link="communities.URL"
              :followers="communities.Followers"
              :posts="communities.Posts"
              :articles="communities.Articles"
              :certification="true"
            />
          </div>
        </div>
      </div>

      <ScheduleEntryAssociate
        v-if="
          certification.certification_level === 'Associate' ||
          certification.certification_level === 'Entry'
        "
        :certification="certification"
        :topics="topics"
      />

      <ScheduleProfessional
        v-else-if="certification.certification_level === 'Professional'"
        :certification="certification"
        :topics="topics"
        :current-exam="currentExam"
        :ex-card-concentration="exCardConcentration"
      />

      <ScheduleExpert
        v-else-if="certification.certification_level === 'Expert'"
        :certification="certification"
        :topics="topics"
        :ex-card-core="exCardCore"
      />

      <ExploreRecommendedResources
        v-if="showRecommendations && catalogData && catalogData.length > 0"
        :catalogData="catalogData"
        :status="status"
      />
      <ExploreResources v-else :viewAllTag="viewAllTag" />
    </div>
  </div>
</template>
