<script lang="ts">
import "@cisco-u/icons/icons/cu-icon-chevron-right.js";
import "@cisco-u/links-external/cu-links-external.js";

import { defineComponent } from "vue";

import learningPathResult from "@/components/contentBits/learningPathResult.vue";
import practiceExamResult from "@/components/contentBits/practiceExamResult.vue";
import { sendLinkCtaTelemetry } from "@/utils/ctaTelemetry";
import isUnauth from "@/utils/isUnauth";

export default defineComponent({
  components: {
    learningPathResult,
    practiceExamResult,
  },
  props: {
    exCardCore: {
      type: Object,
      required: true,
    },
    exCardEntry: {
      type: Object,
      required: true,
    },
    status: {
      type: Object,
      default: null,
    },
    communities: {
      type: Object,
      required: true,
    },
    recommendedPaths: {
      type: Array,
      required: true,
    },
  },
  computed: {
    showPathsOfExam(): any {
      let result: any = [];
      if (!this.exCardCore?.prescribed_prep_material?.length) return result;
      this.exCardCore.prescribed_prep_material?.forEach((material: any) => {
        let path = this.recommendedPaths?.find(
          (path: any) => path.guid == material.guid
        );
        if (path) {
          result = result.concat(path);
        }
      });
      return result;
    },
  },
  methods: {
    isUnauth,
    async sendUnauthTelemetry(title: string) {
      if (this.isUnauth()) {
        await sendLinkCtaTelemetry(this.$route.fullPath, title, true);
      }
    },
  },
});
</script>
<template>
  <div class="border-b-[#A4A5A6] px-4 pb-20 lg:px-44">
    <div class="mt-5 text-[2.563rem] text-black-light">
      <span id="prepare" class="scroll-mt-[7rem]">Prepare</span>
    </div>
    <p class="text-18">
      To earn your certification, you must pass the entry exam.
    </p>
    <div class="mt-8 h-[50px] pb-6 text-22 font-bold text-[#1b1c1d]">
      Training and practice
    </div>
    <div v-if="exCardEntry?.prescribed_prep_material?.length > 0">
      <p class="mb-5 text-18">
        Use the following resources on your certification journey to success.
      </p>
      <div class="flex-row flex-wrap lg:flex">
        <div
          class="flex-row flex-wrap lg:flex"
          v-for="path in showPathsOfExam"
          :key="path"
        >
          <learning-path-result :result="path" :status="status" class="pb-4" />
          <div class="mb-4 ml-5">
            <practice-exam-result
              v-if="exCardEntry?.practiceExam"
              tabindex="0"
              :result="exCardEntry.practiceExam"
              :status="status"
            />
          </div>
          <div class="schedule mt-10 lg:ml-5">
            <p class="text-30">Schedule Entry Exam</p>
            <p class="mb-10 text-18">
              Feeling ready?
              <br />
              If so, you've got this!
            </p>
            <cu-links-external
              v-if="exCardEntry?.acronym"
              @click="sendUnauthTelemetry('Go to CertMetrics')"
              type="line-diagonal"
              :text="'Schedule the ' + exCardEntry.acronym + ' exam today'"
              url="https://cp.certmetrics.com/cisco/en/schedule/schedule-exam"
              linkTitle="Go to CertMetrics"
              noUnderline="true"
              color="blue-light"
              class="mt-[1.5rem]"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="w-[50%] text-18">
      If you can't find any Cisco U. training resources,
      <cu-links-external
        v-if="communities?.URL"
        @click="sendUnauthTelemetry('Go to Communities')"
        type="line-diagonal"
        text="join a community"
        :url="communities.URL"
        linkTitle="Go to Communities"
        noUnderline="true"
        color="blue-light"
        class="mt-[1.5rem]"
      />
      on the Cisco Learning Network to connect with certification experts and
      get learning guidance from your peers.
      <div class="schedule mt-10 lg:ml-5">
        <p class="text-30">Schedule Entry Exam</p>
        <p class="mb-10 text-18">
          Feeling ready? <br />
          If so, you've got this!
        </p>
        <cu-links-external
          v-if="exCardEntry?.exam_number"
          @click="sendUnauthTelemetry('Go to CertMetrics')"
          type="line-diagonal"
          :text="
            'Schedule the ' +
            exCardEntry.exam_number +
            ' ' +
            exCardEntry.acronym +
            ' exam today'
          "
          url="https://cp.certmetrics.com/cisco/en/schedule/schedule-exam"
          linkTitle="Go to CertMetrics"
          noUnderline="true"
          color="blue-light"
          class="mt-[1.5rem]"
        />
      </div>
    </div>
  </div>
</template>
