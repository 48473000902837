import dateFormats from "@/utils/dateFormats";
import getDuration from "@/utils/getDuration";

interface BuildIcon {
  type: string;
  text: string;
}

function singlePluralSimple(count: number, word: string): string {
  const expression = `${count} ${word}`;
  if (count > 1) {
    return `${expression}s`;
  }
  return `${expression}`;
}

function IconDuration(content: any): BuildIcon | undefined {
  if (getDuration(content) != "") {
    return {
      type: "time",
      text: getDuration(content),
    };
  }
  return undefined;
}

export function IconVideos(content: any): BuildIcon | undefined {
  if (content?.content_count > 0) {
    return {
      type: "video-filled",
      text: singlePluralSimple(content.content_count, "Video"),
    };
  }
  return undefined;
}

function IconDifficultyType(content: any): BuildIcon | undefined {
  if (content?.difficulty_type) {
    let iconType: string = "";
    switch (content.difficulty_type) {
      case "Beginner":
        iconType = "novice";
        break;
      case "Intermediate":
        iconType = "associate";
        break;
      case "Advanced":
        iconType = "specialist";
        break;
      default:
        iconType = "expert";
    }
    return {
      type: iconType,
      text: content.difficulty_type,
    };
  }
  return undefined;
}

function IconCourses(content: any): BuildIcon | undefined {
  if (content?.content_count > 0) {
    return {
      type: "article",
      text: singlePluralSimple(content.content_count, "Course"),
    };
  }
  return undefined;
}

function IconCreds(content: any): BuildIcon | undefined {
  if (content?.number_of_credits > 0 && !content?.is_partial) {
    return {
      type: "ce-credits",
      text: singlePluralSimple(content.number_of_credits, "Credit"),
    };
  }
  return undefined;
}
function IconAssessments(content: any): BuildIcon | undefined {
  if (content?.number_of_assessments > 0) {
    return {
      type: "assessment",
      text: singlePluralSimple(content.number_of_assessments, "Assessment"),
    };
  }
  return undefined;
}

function IconVersion(content: any): BuildIcon | undefined {
  if (content?.display_version && content.display_version != "0.0") {
    return {
      type: "all",
      text: "v" + content.display_version,
    };
  }
  return undefined;
}

function IconLabs(content: any): BuildIcon | undefined {
  if (content?.number_of_labs > 0) {
    return {
      type: "lab",
      text: singlePluralSimple(content.number_of_labs, "Lab"),
    };
  }
  return undefined;
}

function IconCalendar(content: any): BuildIcon | undefined {
  if (content?.event_date) {
    return {
      type: "enter-globe",
      text: dateFormats.monthAndYear(content?.event_date),
    };
  }
  return undefined;
}

function IconCreate(content: any): BuildIcon | undefined {
  if (content?.created_at) {
    return {
      type: "event",
      text: "Created " + dateFormats.monthYearComma(content.created_at),
    };
  }
  return undefined;
}
function IconUpdate(content: any): BuildIcon | undefined {
  if (content?.updated_at) {
    return {
      type: "event",
      text: "Updated " + dateFormats.monthYearComma(content.updated_at),
    };
  }
  return undefined;
}

function IconCertificationLevel(content: any): BuildIcon | undefined {
  if (content?.certification_level?.[0]) {
    let iconType: string = "";
    switch (content.certification_level[0]) {
      case "Associate":
        iconType = "associate";
        break;
      case "Expert":
        iconType = "expert";
        break;
      case "Professional":
        iconType = "professional";
        break;
      default:
        iconType = "novice";
    }
    return {
      type: iconType,
      text: content.certification_level[0],
    };
  }
  return undefined;
}

function returnType(cType: any) {
  let rtn = "";
  const path = "learning-path";
  const videoSeries = "video-series";
  const ilt = "instructor-led";
  const practiceExam = "practice-exam";
  switch (cType) {
    case "path":
      rtn = path;
      break;
    case "webinar":
      rtn = "virtual-event";
      break;
    case "videoSeries":
    case "track":
    case videoSeries:
    case "video-track":
      rtn = videoSeries;
      break;
    case "video":
      rtn = "video";
      break;
    case "podcast":
      rtn = "podcast";
      break;
    case "course":
      rtn = "course";
      break;
    case "tutorial":
      rtn = "tutorial";
      break;
    case ilt:
      rtn = ilt;
      break;
    case practiceExam:
      rtn = practiceExam;
      break;
    default:
      rtn = path;
  }
  return rtn;
}

export function buildGarden(content: any, iconLimit: number): Array<any> {
  let rtn: Array<any> = [];
  const calendar = IconCalendar(content);
  const difficulty = IconDifficultyType(content);
  const time = IconDuration(content);
  const courses = IconCourses(content);
  const labs = IconLabs(content);
  const credits = IconCreds(content);
  const assessments = IconAssessments(content);
  const display_version = IconVersion(content);
  const create = IconCreate(content);
  const update = IconUpdate(content);
  const certificationLevel = IconCertificationLevel(content);
  const videos = IconVideos(content);

  switch (returnType(content?.type)) {
    case "podcast":
      rtn.push(time);
      rtn.push(calendar);
      break;
    case "virtual-event":
      rtn.push(time);
      break;
    case "learning-path":
      rtn.push(update);
    case "instructor-led":
      rtn.push(difficulty);
      rtn.push(time);
      rtn.push(credits);
      rtn.push(display_version);
      rtn.push(create);
      rtn = rtn.filter((ele) => ele); // remove undefined values
      if (rtn.length < iconLimit) rtn.push(labs);
      if (rtn.length < iconLimit) rtn.push(courses);
      if (rtn.length < iconLimit) rtn.push(assessments);

      break;
    case "course":
      rtn.push(difficulty);
      rtn.push(time);
      rtn.push(labs);
      rtn.push(create);
      rtn.push(update);
      break;
    case "practice-exam":
      rtn.push(certificationLevel);
      rtn.push(create);
      rtn.push(update);
      break;
    case "tutorial":
      rtn.push(difficulty);
      rtn.push(time);
      rtn.push(create);
      rtn.push(update);
      break;
    case "video-track":
    case "video-series":
    case "video":
      rtn.push(difficulty);
      rtn.push(time);
      rtn.push(videos);
      rtn.push(create);
      rtn.push(update);
      break;
  }
  rtn = rtn.filter((ele) => ele); // remove undefined values
  return rtn;
}

export default buildGarden;
