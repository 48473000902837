<script lang="ts">
import { defineComponent } from "vue";

import ScrolledTelem from "@/components/ScrolledTelem.vue";
import VCarousel from "@/components/VueCarousel.vue";
import catalogService from "@/services/catalogService";
import middlewareService from "@/services/middlewareService";
import resultMap from "@/views/ForYou/recommendResults.json";

import routeMap from "./routeMap.json";

export default defineComponent({
  components: {
    VCarousel,
    ScrolledTelem,
  },
  props: {
    topicsString: {
      type: String,
      default: "",
    },
    contentType: {
      type: String,
      default: "",
    },
    userOnboardingTutorial: {
      type: Array<string>,
      default: [],
    },
    status: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      resultMap,
      queryFetched: "",
      results: [],
      fetched: false,
      isVisible: false,
    };
  },
  computed: {
    resultTarget() {
      return this.resultMap[this.contentType as keyof typeof this.resultMap];
    },
  },
  watch: {
    topicsString(newTopicString, oldTopicString) {
      // only fetch recommendations if the topic string has changed and we haven't already fetched
      if (newTopicString == oldTopicString || !this.isVisible) return;

      this.fetched = false;
      this.results = [];
      setTimeout(() => {
        this.fetchRecommended();
      }, 2000);
    },
    isVisible() {
      if (!this.isVisible) return;
      if (this.queryFetched == this.topicsString && this.fetched) return;
      this.fetchRecommended();
    },
    userOnboardingTutorial() {
      this.fetchRecommended();
    },
  },
  mounted() {
    // listen for the recommendation element to enter the viewport to fetch recommendations
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        this.isVisible = entries[0].isIntersecting;
      },
      {
        rootMargin: "0px 0px 0px 0px",
        threshold: 0.05,
      }
    );
    intersectionObserver.observe(this.$refs.recommendation as HTMLElement);
  },
  methods: {
    fillResults(params: any) {
      const _date = new Date();
      const _today =
        _date.getFullYear() +
        "-" +
        String(_date.getMonth() + 1).padStart(2, "0") +
        "-" +
        _date.getDate().toString().padStart(2, "0") +
        "T23:59:59Z";
      let filtredData = [];

      if (params.type == "webinar") {
        if (params.data) {
          filtredData = params.data?.filter(
            (result: any) => result.catalog_data.event_date > _today
          );
        }
        this.results = filtredData;
      } else {
        this.results = params.data;
      }
    },
    fetchRecommendedData(type: string, data: any) {
      this.fillResults({
        type: type,
        data: data?.filter(({ catalog_data }: any) => {
          let returnResult = false;
          if (catalog_data?.active) {
            returnResult = catalog_data.active == 1;
          }
          if (catalog_data?.title) {
            returnResult = catalog_data.title.length > 0;
          }
          if (catalog_data?.name) {
            returnResult = catalog_data.name.length > 0;
          }
          return returnResult;
        }),
      });
      this.fetched = true;
    },
    fetchRecommended() {
      this.queryFetched = this.topicsString;
      const serviceMap = {
        tutorial: middlewareService.Recommendations.tutorials,
        podcast: middlewareService.Recommendations.podcasts,
        course: middlewareService.Recommendations.courses,
        path: middlewareService.Recommendations.paths,
        webinar: middlewareService.Recommendations.events,
        video: middlewareService.Recommendations.videos,
        "instructor-led": middlewareService.Recommendations.instructorLed,
        "practice-exam": middlewareService.Recommendations.practiceExams,
      };

      serviceMap[this.contentType as keyof typeof serviceMap]()
        .then((data) => {
          if (this.contentType == "tutorial") {
            let tutorials = this.getUserOnBordingTutorial();
            tutorials.then((tutorial) => {
              data = tutorial.concat(data);
              this.fetchRecommendedData(this.contentType, data);
            });
            return;
          }

          let contentTypeName = this.contentType;
          if (this.contentType == "instructor-led") {
            contentTypeName = "ilt";
          } else if (this.contentType == "practice-exam") {
            contentTypeName = "pr-exam";
          } else if (this.contentType == "path") {
            contentTypeName = "learningPath";
          }

          this.fetchRecommendedData(contentTypeName, data);
        })
        .catch(() => {
          // do nothing
        });
    },
    async getUserOnBordingTutorial() {
      if (!this.userOnboardingTutorial.length || this.status?.loginCount > 5)
        return [];
      let tutorial = await catalogService.courses.retrieveCatalogItems(
        this.userOnboardingTutorial,
        "tutorials"
      );
      return tutorial.map((item: any) => {
        return {
          CourseID: item.guid,
          CourseTitle: item.title,
          CourseType: "tutorial",
          catalog_data: {
            showCTA: true,
            ...item,
          },
        };
      });
    },
    exploreType(type: string) {
      return routeMap[type as keyof typeof routeMap] ?? "";
    },
  },
});
</script>

<template>
  <div ref="recommendation">
    <scrolled-telem
      v-show="contentType.length"
      :threshold="100"
      :telem-send="resultTarget.telem"
    >
      <VCarousel
        :resultComponent="resultTarget.component"
        :contentType="contentType"
        :results="results"
        :exploreAllText="resultTarget.lower"
        :fetched="fetched"
        :topicsString="topicsString"
        :view-all-text="`View all ${resultTarget.title}`"
        :view-all-tag="resultTarget.lower"
        :view-all-link="{
          name: exploreType(contentType),
        }"
      >
        <div class="text-18 font-bold leading-[1.2] md:text-22">
          {{ resultTarget.title }}
        </div>
      </VCarousel>
    </scrolled-telem>
  </div>
</template>
