<script lang="ts">
import "@cisco-u/avatar/cu-avatar.js";

import { defineComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";

import MarqueeCardDay0 from "@/layout/Header/MarqueeCard.vue";
import MobileHeader from "@/layout/Header/mobileHeader.vue";
import PageHeader from "@/layout/Header/PageHeader.vue";

export default defineComponent({
  emits: ["close", "open", "update-query"],
  components: {
    MarqueeCardDay0,
    MobileHeader,
    PageHeader,
  },
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    scrolled: {
      type: Boolean,
      default: false,
    },
    showLogo: {
      type: Boolean,
      default: false,
    },
    searchQuery: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showGoal: false,
    };
  },
  computed: {
    ...mapGetters({
      userStatus: "user/status",
      isAdminRole: "user/adminRole",
      isCustomerAdminRole: "user/customerAdminRole",
      isContentAdminRole: "user/contentAdminRole",
    }),
    initials() {
      return this.userStatus?.userInitials ?? "";
    },
    userDisplayName() {
      return this.userStatus?.display_name ?? "";
    },
    showPageHeader() {
      return this.$route?.meta?.pageHeader === true;
    },
    hideMarquee() {
      return this.$route?.meta?.hideMarquee === true;
    },
    showProfileHeader() {
      return this.$route?.meta?.showProfileHeader === true;
    },
    isPathRoute() {
      return this.$route?.name === "Path";
    },
    getUser() {
      if (!this.userStatus) return false;

      this.start();
      return true;
    },
  },

  methods: {
    ...mapMutations({
      setupToggleShowAnyway: "user/setupToggleShowAnyway",
    }),
    closeModal() {
      this.$emit("close");
      this.setupToggleShowAnyway(false);
    },
    openModal() {
      this.$emit("open");
      this.setupToggleShowAnyway(true);
    },
    start() {
      if (
        this.userStatus &&
        this.$route?.name == "ForYou" &&
        !this.userStatus.surveyCompleted
      ) {
        this.openModal();
      }
    },
    updateQuery(newValue: any) {
      this.$emit("update-query", newValue);
    },
  },
});
</script>

<template>
  <div>
    <div
      id="profile"
      :class="[
        'flex flex-col-reverse',
        {
          'min-h-[42rem] lg:min-h-[46rem] xl:min-h-[30rem]':
            showPageHeader && !hideMarquee,
        },
        {
          'min-h-[45.625rem] lg:min-h-[49.625rem] xl:min-h-[33.625rem]':
            !hideMarquee,
        },
      ]"
    >
      <div
        v-show="!hideMarquee"
        :class="[
          'mx-auto flex w-full justify-center pt-24 text-white md:top-[8.625rem] md:justify-between md:pt-0 lg:top-[12.625rem]',
          {
            'md:fixed md:left-0 md:right-0 md:top-20 md:pb-0 lg:top-36':
              showPageHeader,
          },
        ]"
      >
        <div
          :class="[
            'container mx-auto flex justify-center gap-4 md:px-4',
            {
              'my-4': showPageHeader,
              'mb-16 mt-24': !showPageHeader,
            },
          ]"
        >
          <Marquee-Card-Day0
            :status="userStatus"
            @openGoSurveyModal="openModal"
            v-show="getUser"
          />
        </div>
      </div>

      <MobileHeader
        :dark="dark"
        :is-admin-role="isAdminRole"
        :is-customer-admin-role="isCustomerAdminRole"
        :is-content-admin-role="isContentAdminRole"
      />

      <div
        :class="[
          'hidden pt-8 text-white lg:flex lg:w-full lg:flex-col lg:items-start lg:justify-self-start',
          {
            'pb-10 md:fixed md:left-0 md:right-0 md:top-0 md:pb-8':
              showPageHeader,
            'fixed left-0 right-0 top-0 z-[1000] bg-black-dark pb-0':
              isPathRoute,
          },
        ]"
      >
        <page-header
          :dark="true"
          :scrolled="true"
          :show-logo="true"
          instance="profile-header"
          :searchQuery="searchQuery"
          @update-query="updateQuery"
        />
      </div>
    </div>

    <div
      v-if="showProfileHeader"
      :class="[
        'mx-4 mb-[5.625rem] flex flex-row pl-4 md:container md:mx-auto',
        {
          'mt-[14rem]': hideMarquee,
          'mt-[3rem]': !hideMarquee,
        },
      ]"
    >
      <div>
        <cu-avatar
          cisco_two="true"
          :letters="initials"
          width="6rem"
          height="8.0625rem"
          fontSize="2.8125rem"
          class="pointer-events-none"
        ></cu-avatar>
      </div>
      <div class="flex flex-col pl-[44px]">
        <p class="text-3xl text-white">Welcome back,</p>
        <p class="text-7xl leading-[1.3] text-white">{{ userDisplayName }}</p>
      </div>
    </div>
  </div>
</template>
