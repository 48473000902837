import { z } from "zod";

import http from "@/services/http";

const globalProduct = z
  .object({
    id: z.number(),
    content_bundle_id: z.number(),
    global_product_id: z.number(),
    global_product_name: z.string(),
    created_at: z.string().datetime().nullish(),
    updated_at: z.string().datetime().nullish(),
  })
  .passthrough();

const course = z
  .object({
    active: z.number().nullish(),
    adjacent_technology: z.string().nullish(),
    author: z.string(),
    content_provider: z.string(),
    content_type_id: z.number(),
    copyright_holder: z.string(),
    copyright_year: z.number(),
    data_classification: z.string(),
    description: z.string().nullish(),
    duration_sec: z.number(),
    difficulty_type_id: z.number().nullish(),
    difficulty_type: z.string().nullish(),
    technology: z.string().nullish(),
    id: z.number(),
    guid: z.string(),
    is_partial: z.number(),
    is_private: z.number(),
    language: z.string().nullish(),
    presenter: z.string(),
    product_alias: z.string(),
    product_version: z.string(),
    publisher_id: z.number().nullish(),
    publisher_name: z.string().nullish(),
    record_gui: z.string().nullish(),
    skills_associated: z
      .array(z.string())
      .nullish()
      .or(z.string().default("").nullish()),
    solutions: z.array(z.string()).nullish(),
    target_availability_date: z.string().datetime().nullish(),
    title: z.string(),
    type: z.string(),
    url: z.string().nullish(),
    global_products: z.array(globalProduct).nullish(),
    created_at: z.string().datetime().nullish(),
    updated_at: z.string().datetime().nullish(),
    deleted_at: z.string().datetime().nullish(),
  })
  .passthrough();

const trackResponse = z
  .object({
    id: z.number(),
    guid: z.string(),
    name: z.string(),
    description: z.string(),
    summary: z.string(),
    active: z.number(),
    is_partial: z.number(),
    duration_sec: z.number(),
    difficulty_type: z.enum([
      "Beginner",
      "Intermediate",
      "Advanced",
      "Expert",
      "",
    ]),
    type: z.string(),
    number_of_credits: z.number(),
    number_of_labs: z.number(),
    number_of_assessments: z.number(),
    content_count: z.number(),
    technology: z.string(),
    created_at: z.string().datetime().nullish(),
    updated_at: z.string().datetime().nullish(),
    deleted_at: z.string().datetime().nullish(),
    is_private: z.number(),
    content_provider: z.string(),
    solutions: z.array(z.string()).nullish(),
    global_products: z.array(globalProduct).nullish(),
    Courses: z.array(course),
  })
  .passthrough();

export async function fetch(trackID: string) {
  const { data } = await http().get(
    `${window.env.CATALOG_API_LOCATION}/tracks/${trackID}?includeChild=true`
  );
  return trackResponse.parse(data);
}
