import { z } from "zod";

import http from "@/services/http";

const PathTrackStatusRes = z.object({
  progressStatus: z
    .object({
      learningPath: z.array(
        z
          .object({
            pathGUId: z.string(),
            pathId: z.string(),
            pathCompletionDate: z.string().nullish(),
            pathProgressPercentage: z.coerce.number().gte(0).lte(100),
            pathCompletionStatus: z.string(),
            pathTitle: z.string(),
            "track-progress": z.array(
              z
                .object({
                  trackId: z.string(),
                  trackGUId: z.string(),
                  trackTitle: z.string(),
                  trackCompletionDate: z.string().nullish(),
                  trackProgressPercentage: z.coerce.number().gte(0).lte(100),
                  trackCompletionStatus: z.string(),
                })
                .passthrough()
            ),
          })
          .passthrough()
      ),
    })
    .passthrough(),
});

export async function fetch(guid: string) {
  const { data } = await http().get(
    `${window.env.PROFILE_API_LOCATION}/PathTrackStatus?pathGuid=${guid}`
  );
  return PathTrackStatusRes.parse(data);
}

export default PathTrackStatusRes;
