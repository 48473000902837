import { z } from "zod";

import http from "@/services/http";

const Section = z
  .object({
    section: z.object({
      gid: z.string(),
      name: z.string(),
    }),
  })
  .passthrough();

const enumValue = z
  .object({
    gid: z.string(),
    color: z.string(),
    enabled: z.boolean(),
    name: z.string(),
    resource_type: z.string(),
  })
  .passthrough();

const Field = z
  .object({
    name: z.string(),
    gid: z.string(),
    enum_value: enumValue.nullish(),
    text_value: z.string().nullish(),
  })
  .passthrough();

const marqueeData = z.object({
  gid: z.string(),
  name: z.string(),
  custom_fields: z.array(Field),
  memberships: z.array(Section),
});

const marqueeResponse = z
  .object({
    data: z.array(marqueeData),
  })
  .strict();

export async function fetch() {
  const { data } = await http().get(
    `${window.env.APOLLO_MIDDLEWARE_API_LOCATION}/marquees`
  );
  return marqueeResponse.parse(data);
}
