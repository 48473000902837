<script lang="ts">
import { defineComponent } from "vue";

import CertCard from "@/components/contentBits/certCard.vue";
import EventResult from "@/components/contentBits/eventsResult.vue";
import LearningPathResult from "@/components/contentBits/learningPathResult.vue";
import PodCastCard from "@/components/contentBits/podCastCard.vue";
import practiceExamResult from "@/components/contentBits/practiceExamResult.vue";
import TutorialsResult from "@/components/contentBits/tutorialsResult.vue";
import VideosCard from "@/components/contentBits/videosCard.vue";

import typeMap from "./typeMap.json";

export default defineComponent({
  components: {
    CertCard,
    PodCastCard,
    practiceExamResult,
    VideosCard,
    LearningPathResult,
    EventResult,
    TutorialsResult,
  },
  props: {
    catalogData: {
      type: Array,
      default: () => [] as any[],
    },
    status: {
      type: Object,
      default: null,
    },
  },
  computed: {
    catalogDataArray(): any[] {
      return this.catalogData;
    },
  },
  methods: {
    componentType(type: string): string {
      return typeMap[type as keyof typeof typeMap] ?? "";
    },
  },
});
</script>
<template>
  <div class="px-4 pb-20 lg:px-44" v-if="catalogDataArray.length > 0">
    <div class="text-[2.563rem] text-black-light">
      Explore more learning resources
    </div>
    <div class="mb-10 text-18 text-black-light">
      Leverage these resources to supplement your learning journey.
    </div>

    <div class="flex flex-row flex-wrap gap-4">
      <div
        v-for="(catalog, index) in catalogDataArray"
        :key="'recommendation_' + index"
      >
        <div v-if="catalog?.ContentType && catalog?.catalog_data?.type">
          <component
            :is="componentType(catalog?.catalog_data?.type)"
            :result="catalog?.catalog_data"
            :status="status"
          />
        </div>
      </div>
    </div>
  </div>
</template>
