import { z } from "zod";

import http from "@/services/http";

const PostAssessmentAttempt = z
  .object({
    "assessment-attempt-id": z.string(),
    assessmentGUID: z.string(),
    assessmentOutcome: z.string(),
    assessmentType: z.string(),
    "attempts-remaining": z.number(),
    "completed-at": z.string().nullish(),
    "created-at": z.string().nullish(),
    overallPointsAvailable: z.number(),
    overallPointsEarned: z.number(),
    pathId: z.string().nullish(),
    percentageScore: z.number(),
    questionsCorrect: z.number(),
    questionsTotal: z.number(),
    state: z.string(),
    title: z.string(),
    trackId: z.string().nullish(),
    trackGuid: z.string().nullish(),
    pathGuid: z.string().nullish(),
  })
  .passthrough()
  .nullish();

const CourseProficiency = z
  .object({
    courseCompletionStatus: z.string(),
    courseGUID: z.string(),
    courseId: z.string(),
    courseProficiencyDate: z.string().nullish(),
    courseTitle: z.string(),
    learnerProgressPercentage: z.string(),
    markedProficient: z.boolean(),
    courseCompletionDate: z.string().nullish(),
  })
  .passthrough();

const PreAssessmentAttempt = z
  .object({
    "assessment-attempt-id": z.string(),
    assessmentGUID: z.string(),
    assessmentType: z.string(),
    "attempts-remaining": z.number(),
    "completed-at": z.string().nullish(),
    "course-proficiency": z.array(CourseProficiency).nullish(),
    "created-at": z.string().nullish(),
    overallPointsAvailable: z.number(),
    overallPointsEarned: z.number(),
    percentageScore: z.number(),
    state: z.string(),
    title: z.string(),
    trackId: z.string().nullish(),
    pathId: z.string().nullish(),
    trackGuid: z.string().nullish(),
    pathGuid: z.string().nullish(),
    questionsTotal: z.number().nullish(),
    questionsCorrect: z.number().nullish(),
    assessmentOutcome: z.string().nullish(),
  })
  .passthrough()
  .nullish();

const LearnerTrackAssessment = z
  .object({
    vendorID: z.string().nullish(),
    application: z.string().nullish(),
    environment: z.string().nullish(),
    "pre-assessment-attempt": z.array(PreAssessmentAttempt).nullish(),
    "post-assessment-attempt": z.array(PostAssessmentAttempt).nullish(),
  })
  .passthrough();

const dataResponse = z
  .object({
    assessment: LearnerTrackAssessment,
  })
  .passthrough();

export async function fetch(trackGuid: string) {
  const { data } = await http().get(
    `${window.env.PROFILE_API_LOCATION}/LearnerTrackAssessment?trackGuid=${trackGuid}`
  );

  return dataResponse.parse(data);
}
