// This code takes the result of a search and flattens it into a format that is easier to use.
// The only thing that is done is that the raw fields are used.
// Titles can contain snippit fields for highlighting, so we check for that and use the snippit if it exists.

interface RawSnippetResponse {
  raw: string;
  snippet?: string;
}
function rawOrSnippet(response: RawSnippetResponse): string {
  if (response.snippet && response.snippet.includes("<em>")) {
    return response.snippet;
  }

  return response.raw;
}
export default function flattenSearchResult(result: any) {
  if (result) {
    const response = JSON.parse(JSON.stringify(result));

    Object.keys(response).forEach((key: string) => {
      if (key == "title") {
        response.title = rawOrSnippet(response.title);
      } else {
        response[key as keyof typeof response] =
          response[key as keyof typeof response].raw;
      }
    });

    response.id = response.doc_id;
    return response;
  }
  return null;
}
