import {
  fetchCertFacets,
  fetchCertResults,
  fetchUnauthCertFacets,
  fetchUnauthCertResults,
} from "@/services/searchService/certificationSearch";
import {
  fetchFacets,
  fetchTypeResults,
} from "@/services/searchService/searchType";
import * as trackClickThrough from "@/services/searchService/trackClickThrough";

export default {
  trackClickThrough,
  fetchFacets,
  fetchTypeResults,
  fetchCertResults,
  fetchCertFacets,
  fetchUnauthCertFacets,
  fetchUnauthCertResults,
};
