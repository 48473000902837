<script lang="ts">
import "@cisco-u/tags/cu-tags.js";

import { defineComponent } from "vue";

import isUnauth from "@/utils/isUnauth";

export default defineComponent({
  props: {
    certification: {
      type: Object,
      required: true,
    },
    technologies: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: () => "",
    },
  },
  computed: {
    isCCDE() {
      return this.certification.acronym === "CCDE";
    },
    isDate() {
      return new Date() >= new Date("2024-06-04T15:00:00Z");
    },
  },
  methods: {
    isUnauth,
  },
});
</script>
<template>
  <div v-if="certification" class="flex-row px-4 pb-20 lg:flex lg:pl-8">
    <div class="flex flex-col lg:w-[33%]">
      <div
        class="w-20 text-30 lg:text-60"
        style="line-height: 4rem"
        :class="{ 'font-extralight': isUnauth() }"
      >
        {{ certification.acronym }}
      </div>
      <div class="mt-4 text-18">{{ certification.tag_line }}</div>
      <div class="mb-2 mt-10 text-14">What you'll learn</div>
      <div class="flex-row flex-wrap pr-4 md:flex">
        <div
          class="flex-row flex-wrap gap-2 md:flex"
          v-if="technologies.length > 0"
        >
          <div v-for="technology in technologies" :key="technology as string">
            <cu-tags :text="technology" />
          </div>
        </div>
        <div v-else>No topics available</div>
      </div>
      <div
        class="my-2 text-14"
        v-if="
          title != 'Pass the entry exam' &&
          certification.certification_level != 'Associate'
        "
      >
        Pass the core exam
      </div>
      <div
        class="my-2 text-14"
        v-else-if="certification.certification_level === 'Associate'"
      >
        Pass the exam
      </div>
      <div>
        <div v-for="item in certification.exam" :key="item.id">
          <div v-if="item.type == 'core'">
            <cu-tags :text="item.exam_number + ' ' + item.acronym" />
          </div>
        </div>
      </div>
      <div
        class="my-2 text-14"
        v-if="title != 'Pass the core exam' || 'Pass the exam'"
      >
        {{ title }}
      </div>
      <div class="flex-row flex-wrap gap-y-2 md:flex">
        <div v-for="item in certification.exam" :key="item.id">
          <div v-if="item.type != 'core'">
            <cu-tags
              :text="item.exam_number + ' ' + item.acronym"
              class="pr-2"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col lg:w-[50%] lg:pl-[6rem]">
      <div class="pb-6 text-30 text-black-light">
        Why {{ certification.name }}?
      </div>
      <div class="font-16 font-[400] text-black-lightest">
        <p>
          {{ certification.description }}
        </p>
      </div>
      <br />
      <div v-if="isCCDE && isDate">
        <span class="font-medium text-black-lightest">Announcement:</span>
        Now you can become an expert in designing AI and machine learning
        networks. Look for the new CCDE AI Infrastructure elective certification
        module On
        <span class="font-medium text-black-lightest">February 9, 2025</span>.
        <div class="mt-[0.25rem]">
          <cu-links-external
            type="line-diagonal"
            text="Learn about AI in networking: Upskilling with Cisco training and certifications"
            url="https://learningnetwork.cisco.com/s/ai-ccde"
            linkTitle="Go to Communities"
            noUnderline="true"
            color="blue-light"
          />
        </div>
      </div>
      <div v-if="isUnauth()" class="mb-4 mt-8 text-22 text-[#0d0d0e]">
        A new future of job possibilities
      </div>
      <div v-else class="mb-2 mt-8 text-30 text-black-light">
        Pass the first step toward a new career
      </div>
      <div>
        <ul
          class="job-roles mt-5 flex w-2/3 flex-row flex-wrap gap-x-[1.375rem] gap-y-[0.75rem]"
          :class="{ 'font-[400] text-black-lightest': isUnauth() }"
        >
          <li v-for="topic in certification.job_roles" :key="topic">
            {{ topic }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
.job-roles {
  flex: 1 0;
  list-style: none;
  padding: 0;
  margin: var(--space-4) 0 0;
}

.job-roles li {
  position: relative;
  padding-left: 1rem;
  line-height: 1.2;
}

.job-roles li::before {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -0.25rem;
  content: "";
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #74bf4b;
  border-radius: 50%;
}

.job-roles li:not(:first-child) {
  margin-top: var(--space-2);
}
</style>
