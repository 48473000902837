<script lang="ts">
import "@cisco-u/tags/cu-tags.js";
import "@cisco-u/links-external/cu-links-external.js";

import { defineComponent } from "vue";

import { sendLinkCtaTelemetry } from "@/utils/ctaTelemetry";
import isUnauth from "@/utils/isUnauth";
import scheduleExam from "@/views/Certifications/assets/images/schedule-cert-exam-img.png?url";

export default defineComponent({
  props: {
    certification: {
      type: Object,
      required: true,
    },
    topics: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      scheduleExam,
    };
  },
  methods: {
    isUnauth,
    async sendUnauthTelemetry(title: string) {
      if (this.isUnauth()) {
        await sendLinkCtaTelemetry(this.$route.fullPath, title, true);
      }
    },
  },
});
</script>
<template>
  <div class="px-4 pb-20 lg:px-44">
    <div class="mb-2 mt-5 text-[2rem] text-black-light">
      <span id="schedule" class="scroll-mt-[7rem]">Schedule the exam</span>
    </div>
    <p class="text-[1rem]">
      Time to get certified! Schedule and pass your exam.
    </p>
    <div
      class="flex flex-col lg:flex-row"
      :class="{ 'mt-4': isUnauth() }"
      v-if="certification?.exam?.length > 0"
    >
      <div class="mx-auto mt-10 w-[35%] lg:mx-0">
        <img :src="scheduleExam" alt="Schedule the Exam" />
      </div>
      <div
        v-for="(exams, index) in certification.exam"
        :key="exams.id"
        :class="[
          'mt-10 flex w-full flex-col justify-between px-4 pb-4 lg:w-[50%] lg:pl-[5rem]',
          {
            'border-l-2': index % 2,
          },
        ]"
      >
        <div
          class="mb-[3rem] min-h-[3.5rem] text-30"
          :class="isUnauth() ? 'mb-[1rem]' : 'mb-[3rem]'"
        >
          {{ exams.exam_number + " " + exams.acronym }}
        </div>
        <div class="text-12">TIME</div>
        <div class="mb-[2.5rem] min-h-[3rem] text-30">
          {{ exams.duration_sec / 60 }} min
        </div>
        <div
          class="text-12"
          :class="{ 'font-[400] tracking-md': isUnauth() }"
          v-if="topics.length > 0"
        >
          TOPICS COVERED
        </div>
        <div
          class="gap mt-5 flex flex-row flex-wrap"
          :class="isUnauth() ? '' : 'pb-4'"
        >
          <div
            class="flex flex-row flex-wrap gap-2"
            :class="isUnauth() ? '' : 'pb-[3rem]'"
          >
            <div v-for="topic in topics" :key="topic as string">
              <cu-tags :text="topic"></cu-tags>
            </div>
          </div>
        </div>
        <cu-links-external
          type="line-diagonal"
          text="Schedule exam"
          @click="sendUnauthTelemetry('Go to CertMetrics')"
          linkTitle="Go to CertMetrics"
          url="https://cp.certmetrics.com/cisco/en/schedule/schedule-exam"
          noUnderline="true"
          color="blue-light"
          class="mt-[1.5rem]"
        ></cu-links-external>
      </div>
    </div>
  </div>
</template>
