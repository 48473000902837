import http from "@/services/http";

export async function add(basketId: string, orderTotal: number) {
  const { data } = await http().post(
    window.env.COMMERCE_API + "/commerce/basket/payment-instrument",
    {
      basket: {
        basketId: basketId,
      },
      paymentInstrument: {
        amount: orderTotal,
        paymentMethodId: "DIGITAL_RIVER_DROPIN",
      },
    }
  );
  return data;
}

export async function uploadTaxCertificate(formData: FormData) {
  const { data } = await http().post(
    window.env.COMMERCE_API + "/commerce/tax-certificate",
    formData
  );
  return data;
}

export async function getCustomer() {
  const { data } = await http().get(
    window.env.COMMERCE_API + "/commerce/customer"
  );
  return data;
}

export async function getCountrySpec(
  countryCode: string,
  sellingEntity: string
) {
  const { data } = await http().get(
    window.env.COMMERCE_API +
      "/commerce/country-spec?countryCode=" +
      countryCode +
      "&sellingEntity=" +
      sellingEntity
  );
  return data;
}
