import { z } from "zod";

import http from "@/services/http";

const assessmentData = z.array(
  z.object({
    name: z.string(),
    examNumber: z.string(),
    state: z.string(),
    result: z.string(),
    score: z.number(),
    targetScore: z.number().nullish(),
    totalQuestions: z.number(),
    correctAnswers: z.number(),
    assessmentDate: z.string(),
    objectives: z
      .array(
        z.object({
          name: z.string(),
          percentage: z.number(),
          id: z.string(),
          courseObjectives: z
            .array(
              z.object({
                courseName: z.string(),
                courseUrl: z.string(),
                id: z.string(),
              })
            )
            .nullish(),
        })
      )
      .nullish(),
    statistics: z.object({
      averageScore: z.number(),
      highestScore: z.number(),
      totalAttempts: z.number(),
      previousScore: z.number().nullish(),
    }),
  })
);

export async function learnerAssessment(guid: string) {
  if (!window.env.APOLLO_MIDDLEWARE_API_LOCATION || !guid || guid == "") {
    return;
  }
  const { data } = await http().get(
    `${window.env.APOLLO_MIDDLEWARE_API_LOCATION}/assessments/${guid}`
  );

  if (!data) {
    return;
  }
  return assessmentData.parse(data.data);
}
