<script lang="ts">
import "@cisco-u/checkbox/cu-checkbox.js";
import "@cisco-u/radio-buttons/cu-radio-buttons.js";

import { startCase } from "lodash-es";
import { defineComponent } from "vue";

import expandCollapseHeading from "@/views/Search/SearchFilterSidebar/expandCollapseHeading.vue";
import FilterCount from "@/views/Search/SearchFilterSidebar/FilterCount.vue";

export default defineComponent({
  components: { expandCollapseHeading, FilterCount },

  emits: ["filter-to", "expanded"],

  expose: [
    "collapse",
    "ToggleFromModel",
    "selectedFilters",
    "swapArrayElements",
  ],

  props: {
    facet: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      expanded: true,
      filters: [] as string[],
    };
  },

  mounted() {
    this.filters = this.routeQueryTopic as string[];
  },

  computed: {
    eventTypes() {
      let result = [
        { value: "thisWeek", name: "This Week", count: 0 },
        { value: "next30Days", name: "Next 30 Days", count: 0 },
        { value: "nextWeek", name: "Next Week", count: 0 },
        { value: "30+days", name: "More than 30 Days", count: 0 },
      ];

      if (this.facet?.data?.length) {
        const data = this.facet.data;
        result = result.map((timeframe, index) => {
          timeframe.count = data[index as keyof typeof data]?.count ?? 0;
          return timeframe;
        });
      }
      this.swapArrayElements(result, 1, 2);
      return result;
    },
    routeQueryTopic() {
      if (!this.$route.query || !this.$route.query.to) {
        return [];
      }
      let target = this.$route.query.to;
      if (typeof target == "string") {
        target = target.split(",");
      }

      return [target].flat();
    },
  },

  methods: {
    swapArrayElements(arr: any, indexA: number, indexB: number) {
      // Ensure the indices are within the bounds of the array
      if (
        indexA < 0 ||
        indexA >= arr.length ||
        indexB < 0 ||
        indexB >= arr.length
      ) {
        throw new Error("Index out of bounds");
      }

      const temp = arr[indexA as keyof typeof arr];
      arr[indexA as keyof typeof arr] = arr[indexB as keyof typeof arr];
      arr[indexB as keyof typeof arr] = temp;
    },
    checkActive(value: string) {
      return this.routeQueryTopic?.includes(value);
    },

    selectedFilters() {
      return this.filters;
    },

    collapse(expand: boolean) {
      this.expanded = expand;
    },

    startCase,

    expandCollapseInternal() {
      this.expanded = !this.expanded;
      this.$emit("expanded", true);
    },

    ToggleFromModel(value: string) {
      if (!this.filters) return;

      if (this.filters.includes(value)) {
        this.filters = this.filters.filter(
          (matchingValue) => matchingValue !== value
        );
      } else {
        this.filters.push(value);
      }
      this.$emit("filter-to", this.filters);
    },
  },
});
</script>

<template>
  <div class="min-w-[17.5rem]">
    <div class="mr-4 mt-4 border-t">
      <expandCollapseHeading
        data-cy="search-filter-heading-schedule-type"
        @expand-collapse-internal="expandCollapseInternal"
        :expanded="expanded"
      >
        Schedule
      </expandCollapseHeading>
      <div v-show="expanded" data-cy="search-filter-schedule-field-list">
        <ul class="p-1" v-for="content in eventTypes" :key="content.value">
          <li
            data-cy="content-type-field"
            class="flex flex-row items-center justify-between whitespace-nowrap text-16 tracking-[125%] text-black-dark"
          >
            <cu-checkbox
              @input="ToggleFromModel(content.value)"
              :title="`${content.value} (${content.count})`"
              :checked="checkActive(content.value)"
            >
              {{ startCase(content.name) }}
            </cu-checkbox>
            <FilterCount>{{ content.count }}</FilterCount>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
