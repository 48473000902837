<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import forYou from "@/views/ForYou/forYou.vue";

export default defineComponent({
  components: {
    forYou,
  },
  computed: {
    ...mapGetters({
      obSurvey: "user/obSurvey",
      obSurveyAnswers: "user/getSurveyAnswers",
      boxShowable: "user/boxShowable",
      getSurveyAnswers: "user/getSurveyAnswers",
    }),
  },
});
</script>

<template>
  <forYou
    :onboard-survey="obSurveyAnswers"
    :get-survey-answers="getSurveyAnswers"
    :box-showable="boxShowable"
  />
</template>
