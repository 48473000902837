<script lang="ts">
//@ts-ignore
import "@cisco-u/icons/icons/cu-icon-associate.js";
import "@cisco-u/icons/icons/cu-icon-expert.js";
import "@cisco-u/icons/icons/cu-icon-professional.js";
import "@cisco-u/icons/icons/cu-icon-novice.js";

import { defineComponent } from "vue";

import isUnauth from "@/utils/isUnauth";

export default defineComponent({
  props: {
    root: {
      type: String,
      default: "",
    },
    location: {
      type: String,
      default: "",
    },
    level: {
      type: String,
      default: "",
    },
  },
  methods: {
    isUnauth,
  },
});
</script>

<template>
  <div class="pl-8">
    <span class="breadcrumb pb-5">
      <router-link
        to="/certifications"
        class="text-gray"
        :class="isUnauth() ? 'text-[16px] font-normal' : 'text-[1.2rem]'"
      >
        {{ root }}
      </router-link>
      /
      <span
        class="text-black-darkest"
        :class="isUnauth() ? 'text-[16px] font-normal' : 'text-[1.2rem]'"
        >{{ location }}</span
      >
    </span>
    <div class="py-10" :class="{ flex: isUnauth() }">
      <span class="pr-2">
        <cu-icon-associate
          v-if="level === 'Associate'"
          class="text-[1.25rem] text-blue-light"
        />
        <cu-icon-expert
          v-else-if="level === 'Expert'"
          class="text-[1.25rem] text-black-dark"
        />
        <cu-icon-professional
          v-else-if="level === 'Professional'"
          class="text-[1.25rem] text-blue-dark"
        />
        <cu-icon-novice
          v-else-if="level === 'Entry'"
          class="text-[1.25rem] text-blue-lightest"
        />
      </span>
      <span
        :class="
          isUnauth()
            ? 'text-[14px] font-[400] tracking-md'
            : 'text-[20px] text-[#606162]'
        "
        >{{ level.toUpperCase() }}</span
      >
    </div>
  </div>
</template>
