import * as addUserHistory from "@/services/profileService/addUserHistory";
import * as browsingHistory from "@/services/profileService/browsingHistory";
import * as cdlUser from "@/services/profileService/cdlUser";
import * as LearnerTrackAssessment from "@/services/profileService/learnerTrackAssessment";
import * as myList from "@/services/profileService/myList";
import * as myProfile from "@/services/profileService/myProfile";
import * as pathTrackStatus from "@/services/profileService/pathTrackStatus";
import * as practiceExamsScore from "@/services/profileService/practiceExamsScore";
import * as surveys from "@/services/profileService/surveys";
import * as userActions from "@/services/profileService/userActions";
import * as userProgress from "@/services/profileService/userProgress";
import * as userStatus from "@/services/profileService/userStatus";

export default {
  myList,
  myProfile,
  browsingHistory,
  addUserHistory,
  pathTrackStatus,
  surveys,
  userStatus,
  LearnerTrackAssessment,
  userProgress,
  cdlUser,
  userActions,
  practiceExamsScore,
};
