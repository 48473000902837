<script lang="ts">
import { defineComponent } from "vue";

import telemetry from "@/utils/telemetry";
export default defineComponent({
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    logoLinkTagHeader: {
      type: String,
      default: "",
    },
  },
  computed: {
    isRegistrationRoute() {
      return this.$route?.name
        ? this.$route.name == "permission-denied"
        : false;
    },
  },
  methods: {
    sendTelemetry() {
      if (this.$route.name === "error") {
        telemetry.custom404("learner-navPath", this.$route.fullPath, "Cisco U");
      }
    },
  },
});
</script>

<template>
  <router-link
    tabindex="0"
    v-if="!isRegistrationRoute"
    to="/for-you"
    class="siteLogo flex h-7 w-32 gap-2 font-slight leading-none"
    :data-cy="!dark ? logoLinkTagHeader : `${logoLinkTagHeader}-dark`"
    @click.prevent="sendTelemetry()"
  >
    <span>Cisco</span>
    <img
      width="25.06"
      height="28"
      :class="['flex pb-[0.125rem]', { invert: !dark }]"
      src="@/assets/images/U_logo.png?url"
      alt="U."
    />
  </router-link>
  <div
    class="siteLogo flex h-7 w-32 gap-2 font-slight leading-none"
    v-else
    data-cy="logo-link"
  >
    <span>Cisco</span>
    <img
      width="25.06"
      height="28"
      :class="['flex pb-[0.125rem]', { invert: !dark }]"
      src="@/assets/images/U_logo.png?url"
      alt="U."
    />
  </div>
</template>
