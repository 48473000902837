<script lang="ts">
import "@cisco-u/checkbox/cu-checkbox.js";
import "@cisco-u/radio-buttons/cu-radio-buttons.js";
import "@cisco-u/banner/cu-banner.js";

import { difference, isEmpty, uniq } from "lodash-es";
import { defineComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";

import exploreBannerXs from "@/assets/images/explore-banner-xs.png?url";
import { fetchFacets } from "@/services/searchService/searchType";
import type { SearchRequest } from "@/services/searchService/types";
import { sendButtonCtaTelemetry } from "@/utils/ctaTelemetry";
import isUnauth from "@/utils/isUnauth";
import ContentTypeFilter from "@/views/Search/SearchFilterSidebar/ContentTypeFilter.vue";
import ScheduleFilter from "@/views/Search/SearchFilterSidebar/ScheduleFilter.vue";
import SearchFilterTopicVue from "@/views/Search/SearchFilterSidebar/SearchFilterTopic.vue";
import SubscriptionLevelFilter from "@/views/Search/SearchFilterSidebar/SubscriptionLevelFilter.vue";
import FilterLoader from "@/views/Skeleton/Search/FilterLoader.vue";

const learningPaths = "learning-paths";
const hasSearchedEmit = "has-searched";
const videoTrack = "video-track";
const instructorLed = "instructor-led";
const path = "path";
const practiceExam = "practice-exam";
const webinar = "webinar";

function deriveContentType(type: string) {
  const typeMap = {
    courses: "course",
    tutorials: "tutorial",
    podcasts: "podcast",
    videos: "video",
    "video-series": videoTrack,
    "events-and-webinars": webinar,
    "learning-paths": path,
    "practice-exams": practiceExam,
    "instructor-led": instructorLed,
  };
  return typeMap[type as keyof typeof typeMap] ?? "";
}

export default defineComponent({
  emits: [
    "filters-updated",
    "update-to",
    "toggle-see-all",
    "result-count-update",
    hasSearchedEmit,
    "search-type-counts",
  ],

  components: {
    SearchFilterTopicVue,
    ContentTypeFilter,
    ScheduleFilter,
    SubscriptionLevelFilter,
    FilterLoader,
  },

  props: {
    page: {
      type: Object,
      default: () => ({
        current: 1,
        size: 6,
      }),
    },
    showBanner: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      facets: null as any,
      showMySubscriptions: false,
      filters: [],
      showAllFilters: true,
      to: null as string | null,
      resultCount: 0,
      lastType: "",
      exploreBannerXs,
      joinCiscoText: "Join Cisco U. Free",
    };
  },

  computed: {
    ...mapGetters({
      status: "user/status",
      subscriptionLevels: "content/contentBundles",
      firstTimeHere: "user/firstTimeExploreSubscriptions",
    }),
    routeQueryTopic() {
      if (this.$route?.query?.subscription?.length) {
        let subscription = this.$route.query.subscription as string[];
        if (typeof subscription == "string") {
          // @ts-ignore
          subscription = subscription?.split(",");
        }
        return subscription.map((subs: string) => parseInt(subs, 10));
      }
      return [];
    },
    contentTypeFacet() {
      return this?.facets?.type ? this.facets.type[0] : [];
    },
    eventDateFacet() {
      return this?.facets?.event_date ? this.facets.event_date[0] : {};
    },
    subScriptionLevelIds(): number[] {
      if (!this.status?.bundles?.entitlements) {
        return [];
      }
      return this.status.bundles.entitlements.map((level: any) => {
        return level.content_bundle_id;
      });
    },
    canUseSubscriptions(): boolean {
      const isSearchAllOrExploreAll = ["search-all", "all"].includes(
        this.$route.name as string
      );
      let routeName = this.$route.name as string;

      const isOnSearchOrExploreTypePages =
        routeName?.startsWith("explore-") || routeName?.startsWith("search-");
      const isUsingAnAllowedType = [
        learningPaths,
        "paths",
        path,
        "course",
        "courses",
      ].includes(
        (this.$route.params?.type as string) ||
          (this.$route.meta?.type as string)
      );
      return (
        (isSearchAllOrExploreAll ||
          (isOnSearchOrExploreTypePages && isUsingAnAllowedType)) &&
        this.facets?.content_bundle?.length > 0
      );
    },
    numberOfCredits(): boolean {
      return (
        (this.facets?.number_of_credits &&
          Object.keys(this.facets?.number_of_credits[0]).length > 0) === true
      );
    },
  },
  beforeUpdate() {
    const subscriptionFilter = this.getSubscFilterRef();
    const filter = subscriptionFilter?.selectedFilters();

    const isExplorePageSubscriptionFirstTimePage = [
      "/explore",
      "/paths",
      "/courses",
    ].includes(this.$route.fullPath);
    const hasVisitedThisPageBefore = this.firstTimeHere?.includes(
      this.$route.fullPath
    );
    if (
      !this.isUnauth() &&
      !hasVisitedThisPageBefore &&
      this.subScriptionLevelIds.length > 0 &&
      isExplorePageSubscriptionFirstTimePage &&
      (subscriptionFilter == undefined ||
        (filter.keys.length == 0 && this.showMySubscriptions))
    ) {
      let existingFirstTimeHere = this.firstTimeHere;
      existingFirstTimeHere?.push(this.$route.fullPath);
      this.setfirstTimeExploreSubscriptions(existingFirstTimeHere);
      const existedRoute = this.$route;
      const currentQueryParams = { ...existedRoute.query };
      const queryParamsObj = {
        subscription: this.subScriptionLevelIds.join(","),
      };
      const newQueryParams = { ...currentQueryParams, ...queryParamsObj };
      this.$router.push({ path: existedRoute.path, query: newQueryParams });
    }
    const cType = this.getType(this.$route.params?.type as string);
    if (this.lastType != cType) {
      this.lastType = cType;
      let certiLevelFacetFilters = this.$refs
        .certiLevelFacetFilters as typeof SearchFilterTopicVue;
      let publisherFacetFilters = this.$refs
        .publisherFacetFilters as typeof SearchFilterTopicVue;
      let techTypeFilter = this.$refs
        .techFacetFilters as typeof SearchFilterTopicVue;
      let creditsFacetFilters = this.$refs
        .creditsFacetFilters as typeof SearchFilterTopicVue;
      let levelFacetFilters = this.$refs
        .levelFacetFilters as typeof SearchFilterTopicVue;

      certiLevelFacetFilters?.initFilters();

      levelFacetFilters?.initFilters();

      publisherFacetFilters?.initFilters();

      techTypeFilter?.initFilters();

      creditsFacetFilters?.initFilters();
    }
  },

  mounted() {
    this.handleSubLevels();
    this.fetchFacetData();
  },

  watch: {
    resultCount() {
      this.$emit("result-count-update", this.resultCount);
    },
    $route() {
      this.handleSubLevels();
      this.fetchFacetData();
    },
    subScriptionLevelIds() {
      this.handleSubLevels();
    },
  },

  methods: {
    ...mapMutations({
      setfirstTimeExploreSubscriptions: "user/setfirstTimeExploreSubscriptions",
    }),
    isUnauth,
    getType(type: string): string {
      const keyMap = {
        path: path,
        "learning-paths": path,
        webinar: webinar,
        "events-and-webinars": webinar,
        webinars: webinar,
        "instructor-led": instructorLed,
        podcast: "podcast",
        podcasts: "podcast",
        video: "video",
        videos: "video",
        "video-track": videoTrack,
        "video-series": videoTrack,
        tutorial: "tutorial",
        tutorials: "tutorial",
        "practice-exam": practiceExam,
        "practice-exams": practiceExam,
      };
      return keyMap[type as keyof typeof keyMap] ?? "course";
    },
    correctRoute(type: string | string[]) {
      switch (type) {
        case "course":
          this.$route.params.type = "courses";
          break;
        case path:
          this.$route.params.type = learningPaths;
          break;
        case "tutorial":
          this.$route.params.type = "tutorials";
          break;
        case "podcast":
          this.$route.params.type = "podcasts";
          break;
      }
    },
    async fetchFacetData() {
      this.correctRoute(this.$route.params?.type);
      const isExplorePage = ["all", "explore-type"].includes(
        this.$route.name as string
      );
      if (!isExplorePage && this.$route?.query?.query == "") {
        return;
      }
      this.$emit(hasSearchedEmit, false);
      let postObj = {
        query: this.$route?.query?.query ?? "",
        page: this.page,
      } as SearchRequest;

      if (isExplorePage) {
        postObj.explore = true;
      }

      if (this.$route.params?.type) {
        postObj.type = deriveContentType(this.$route.params.type as string);
      }
      if (this.$route.meta?.type) {
        postObj.type = deriveContentType(this.$route.meta.type as string);
      }
      await fetchFacets(postObj, this.$route.query, this.isUnauth())
        .then((data) => {
          this.facets = data.facets ?? null;
          this.resultCount = data.meta?.page?.total_results ?? 0;
          this.$emit(hasSearchedEmit, true);

          // based on types, emit the counts so we can show / hide result rows on search-all page
          if (
            this.facets?.type?.length &&
            this.facets?.type[0]?.data?.length > 0
          ) {
            this.$emit("search-type-counts", this.facets.type[0].data);
          }
        })
        .catch(() => {
          // do nothing
        });
    },
    handleSubLevels() {
      let levels = this.subScriptionLevelIds;
      levels = levels.sort((a, b) => a - b);
      let routeLevels = this.routeQueryTopic;
      routeLevels = routeLevels.sort((a, b) => a - b);

      this.showMySubscriptions =
        levels.length !== 0 &&
        routeLevels.length !== 0 &&
        difference(levels, routeLevels).length === 0;
    },
    filterSelected() {
      let collectedFilters: any = [];
      let contentTypeFilter = this.$refs[
        "content-type-filter"
      ] as typeof ContentTypeFilter;
      let techTypeFilter = this.$refs
        .techFacetFilters as typeof SearchFilterTopicVue;
      let creditsFacetFilters = this.$refs
        .creditsFacetFilters as typeof SearchFilterTopicVue;
      let levelFacetFilters = this.$refs
        .levelFacetFilters as typeof SearchFilterTopicVue;
      let publisherFacetFilters = this.$refs
        .publisherFacetFilters as typeof SearchFilterTopicVue;
      let scheduleFilter = this.$refs[
        "schedule-filter"
      ] as typeof ScheduleFilter;
      let subscriptionFilter = this.getSubscFilterRef();
      let certiLevelFacetFilters = this.$refs
        .certiLevelFacetFilters as typeof SearchFilterTopicVue;

      if (contentTypeFilter) {
        let filterResults = contentTypeFilter?.selectedFilters();

        filterResults.forEach((result: any) => {
          collectedFilters.push(result);
        });
      }

      if (techTypeFilter) {
        collectedFilters.push(techTypeFilter?.selectedFilters());
      }

      if (creditsFacetFilters) {
        collectedFilters.push(creditsFacetFilters?.selectedFilters());
      }

      if (levelFacetFilters) {
        collectedFilters.push(levelFacetFilters?.selectedFilters());
      }

      if (publisherFacetFilters) {
        collectedFilters.push(publisherFacetFilters?.selectedFilters());
      }

      if (scheduleFilter) {
        collectedFilters.push(scheduleFilter?.selectedFilters());
      }

      if (subscriptionFilter) {
        let subscriptionFiltersResult = subscriptionFilter?.selectedFilters();

        // make sure they are unique
        subscriptionFiltersResult.keys = uniq(
          subscriptionFiltersResult.keys.flat()
        );

        collectedFilters.push(subscriptionFiltersResult);
      }
      if (certiLevelFacetFilters) {
        // @ts-ignore
        collectedFilters.push(certiLevelFacetFilters?.selectedFilters());
      }
      collectedFilters = collectedFilters.filter(
        (value: any) => !isEmpty(value)
      );
      this.$emit("filters-updated", collectedFilters);
    },
    toggleSeeAll(type: string) {
      this.$emit("toggle-see-all", type);
    },
    updateTo(toValue: string) {
      this.$emit("update-to", toValue);
    },
    checkGlobal(globalExpand: boolean) {
      let contentTypeFilter = this.$refs[
        "content-type-filter"
      ] as typeof ContentTypeFilter;
      let techTypeFilter = this.$refs
        .techFacetFilters as typeof SearchFilterTopicVue;
      let creditsFacetFilters = this.$refs
        .creditsFacetFilters as typeof SearchFilterTopicVue;
      let scheduleFilter = this.$refs[
        "schedule-filter"
      ] as typeof ScheduleFilter;
      let subscriptionFilter = this.getSubscFilterRef();
      let levelFacetFilters = this.$refs
        .levelFacetFilters as typeof SearchFilterTopicVue;
      let publisherFacetFilters = this.$refs
        .publisherFacetFilters as typeof SearchFilterTopicVue;
      let certiLevelFacetFilters = this.$refs
        .certiLevelFacetFilters as typeof SearchFilterTopicVue;

      contentTypeFilter?.collapse(globalExpand);

      techTypeFilter?.collapse(globalExpand);

      levelFacetFilters?.collapse(globalExpand);

      publisherFacetFilters?.collapse(globalExpand);

      scheduleFilter?.collapse(globalExpand);

      subscriptionFilter?.collapse(globalExpand);

      certiLevelFacetFilters?.collapse(globalExpand);

      creditsFacetFilters?.collapse(globalExpand);
    },
    informGlobal() {
      this.showAllFilters = false;
    },
    setStatus() {
      this.showAllFilters = !this.showAllFilters;
      this.checkGlobal(this.showAllFilters);
    },
    setMySubscriptionFilter() {
      this.showMySubscriptions = !this.showMySubscriptions;
    },
    getSubscFilterRef() {
      return this.$refs[
        "subscription-filter"
      ] as typeof SubscriptionLevelFilter;
    },
    async sendTelemetry(title: string) {
      if (this.isUnauth()) {
        await sendButtonCtaTelemetry(this.$route.fullPath, title, true);
      }
      window.location.href = window.location.origin + "/login";
    },
  },
});
</script>

<template>
  <div class="flex flex-grow-0 lg:mt-20">
    <filter-loader v-if="facets == null" />
    <div
      v-else
      data-cy="search-filters"
      class="mb-10 flex w-[17.5rem] flex-col pr-4"
    >
      <div v-if="facets != null">
        <div class="flex justify-between pb-4" v-show="resultCount">
          <strong class="text-22">Filter</strong>
          <button
            tabindex="0"
            data-cy="search-filter-global-expand"
            @click="setStatus"
            @keyup.space="setStatus"
            @keyup.enter="setStatus"
            class="text-14 font-light leading-[120%] text-blue"
          >
            <span v-if="showAllFilters">Collapse all</span>
            <span v-else>Expand all</span>
          </button>
        </div>
        <div v-if="canUseSubscriptions && !isUnauth() && resultCount">
          <cu-checkbox
            @input="setMySubscriptionFilter"
            :checked="showMySubscriptions"
          >
            My Subscription
          </cu-checkbox>
        </div>

        <div>
          <search-filter-topic-vue
            v-if="
              facets.difficulty_type?.length && $route.name != 'explore-exams'
            "
            :topic="facets.difficulty_type[0]"
            @filter-selected="filterSelected"
            @expanded="informGlobal"
            ref="levelFacetFilters"
          />

          <search-filter-topic-vue
            v-if="facets.technology?.length"
            :topic="facets.technology[0]"
            @filter-selected="filterSelected"
            @expanded="informGlobal"
            ref="techFacetFilters"
          />

          <search-filter-topic-vue
            v-if="facets.certification_level?.length"
            :topic="facets.certification_level[0]"
            @filter-selected="filterSelected"
            @expanded="informGlobal"
            ref="certiLevelFacetFilters"
          />

          <SubscriptionLevelFilter
            v-if="canUseSubscriptions || $route.name == 'explore-exams'"
            :topic="facets?.content_bundle[0]"
            @filter-selected="filterSelected"
            :status="status"
            :subscriptionLevels="subscriptionLevels"
            :showMySubscriptions="showMySubscriptions"
            @expanded="informGlobal"
            ref="subscription-filter"
          />

          <content-type-filter
            :facets="contentTypeFacet"
            @toggle-all="toggleSeeAll"
            @filter-selected="filterSelected"
            @expanded="informGlobal"
            ref="content-type-filter"
          />

          <search-filter-topic-vue
            v-if="numberOfCredits"
            :topic="facets.number_of_credits[0]"
            @filter-selected="filterSelected"
            @expanded="informGlobal"
            ref="creditsFacetFilters"
          />

          <search-filter-topic-vue
            v-if="facets.publisher_name?.length && canUseSubscriptions"
            :topic="facets.publisher_name[0]"
            @filter-selected="filterSelected"
            @expanded="informGlobal"
            ref="publisherFacetFilters"
          />

          <ScheduleFilter
            v-if="$route.name == 'explore-events'"
            @filter-to="updateTo"
            @expanded="informGlobal"
            ref="schedule-filter"
            :facet="eventDateFacet"
          />
        </div>

        <cu-banner
          v-if="isUnauth() && showBanner"
          data-cy="search-filter-banner"
          dark=""
          basefont="sm"
          card=""
          :leftsrc="exploreBannerXs"
          class="search-filter-banner mt-4"
        >
          <h3 style="font-size: 1.6875rem">
            There's always more to learn <br />in Cisco U.
          </h3>
          <p>
            Get recommendations. <br />Bookmark your favorites. <br />Track your
            progress.
          </p>
          <cu-buttons
            btntype="primary"
            :value="joinCiscoText"
            size="sm"
            style="margin-top: 0"
            @click="() => sendTelemetry(joinCiscoText)"
          ></cu-buttons>
        </cu-banner>
      </div>
    </div>
  </div>
</template>

<style scoped>
.search-filter-banner {
  --cu-banner-img-left-size: 22.6875rem;
  --cu-banner-img-left-pos-left: -1.25rem;
  --cu-banner-img-left-pos-top: -1.625rem;
}
</style>
