import { createApp } from "vue";

import App from "@/App.vue";
import setSessionId from "@/env/setSessionId";
await setSessionId();
import router from "@/router";

import populateApp from "./app";

// Create the app

const app = createApp(App);

populateApp(app);
// When the routes are ready, start the app
router.isReady().then(() => {
  app.mount("#app");
});
