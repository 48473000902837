<script lang="ts">
import "@cisco-u/checkbox/cu-checkbox.js";
import "@cisco-u/radio-buttons/cu-radio-buttons.js";

import { uniq } from "lodash-es";
import { defineComponent } from "vue";

import expandCollapseHeading from "@/views/Search/SearchFilterSidebar/expandCollapseHeading.vue";
import FilterCount from "@/views/Search/SearchFilterSidebar/FilterCount.vue";

const filterSelected = "filter-selected";
export default defineComponent({
  emits: ["expanded", filterSelected],

  expose: ["collapse", "selectedFilters"],

  components: {
    expandCollapseHeading,
    FilterCount,
  },

  props: {
    topic: {
      type: Object,
      default: () => ({}),
    },
    showMySubscriptions: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Object,
      default: () => ({}),
    },
    subscriptionLevels: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      expanded: true,
      filters: [] as number[],
    };
  },

  computed: {
    subScriptionLevelIds(): number[] {
      if (!this.status?.bundles?.entitlements) {
        return [];
      }
      return this.status.bundles.entitlements.map((level: any) => {
        return level.content_bundle_id;
      });
    },
    routeQueryTopic() {
      if (!this.$route.query || !this.$route.query.subscription) {
        return [];
      }
      let subscription = this.$route.query.subscription;

      if (typeof subscription == "string") {
        subscription = subscription.split(",");
      }
      return [subscription]
        .flat()
        .map((subscript: any) => parseInt(subscript, 10));
    },

    levels() {
      const allowed = this.subscriptionLevels
        .filter((level: any) => {
          return [
            "Cisco U. Essentials",
            "Cisco U. Free",
            "Cisco U. All Access",
          ].includes(level.name);
        })
        .map((level: any) => {
          return {
            ...level,
            count:
              this.topic?.data?.filter((subscriptionLevel: any) => {
                return subscriptionLevel.value == level.id;
              })[0]?.count || 0,
          };
        });

      return [
        allowed?.filter((level: any) => level.name === "Cisco U. Free")[0],
        allowed?.filter(
          (level: any) => level.name === "Cisco U. Essentials"
        )[0],
        allowed?.filter(
          (level: any) => level.name === "Cisco U. All Access"
        )[0],
      ];
    },
    levelsCount() {
      if (!this.levels.length) {
        return 0;
      }
      return this.levels.reduce((acc, level) => {
        if (!level || !level.count) {
          return acc;
        }
        return acc + level.count;
      }, 0);
    },
  },

  watch: {
    showMySubscriptions() {
      if (this.showMySubscriptions && this.subScriptionLevelIds) {
        let filterSet = this.filters;
        let subScriptionSet = this.subScriptionLevelIds;
        this.filters = uniq(filterSet.concat(subScriptionSet));
        this.$emit(filterSelected);
      } else if (this.subScriptionLevelIds) {
        this.filters = uniq(
          this.filters
            .filter((id: number) => !this.subScriptionLevelIds.includes(id))
            .flat()
        );
        this.$emit(filterSelected);
      }
    },
  },

  mounted() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.filters = [this.routeQueryTopic].flat();
    },

    checkActive(value: number) {
      return this.routeQueryTopic?.includes(value);
    },

    collapse(expand: boolean) {
      this.expanded = expand;
    },

    expandCollapseInternal() {
      this.expanded = !this.expanded;
      this.$emit("expanded", true);
    },

    selectedFilters() {
      return {
        name: "content_bundle",
        keys: JSON.parse(JSON.stringify(this.filters)),
      };
    },

    ToggleFromModel(value: number) {
      if (this.filters.includes(value)) {
        this.filters = this.filters.filter(
          (matchingValue) => matchingValue !== value
        );
      } else {
        this.filters.push(value);
      }
      this.$emit(filterSelected);
    },
  },
});
</script>

<template>
  <div class="min-w-[17.5rem]" v-show="levelsCount">
    <div class="mr-4 mt-4 border-t">
      <expandCollapseHeading
        :data-cy="`subscription-level-heading-NAME`"
        @expand-collapse-internal="expandCollapseInternal"
        :expanded="expanded"
      >
        Subscription Levels
      </expandCollapseHeading>
      <div data-cy="subscription-level-field-list" v-if="expanded">
        <ul class="p-1">
          <li
            v-for="level in levels"
            :key="level"
            data-cy="subscription-level"
            class="flex flex-row items-center justify-between whitespace-nowrap text-16 tracking-[125%] text-black-dark"
          >
            <cu-checkbox
              @input="ToggleFromModel(level?.id)"
              :checked="checkActive(level?.id)"
            >
              {{ level?.name }}
              <FilterCount>({{ level?.count }})</FilterCount>
            </cu-checkbox>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
