import profileService from "@/services/profileService";
import * as telemetryService from "@/services/telemetryService";

const survey = {
  response: (s: any, q: any, a1: any, a2: any, a3: any, u: any) => {
    return profileService.surveys.updateSurveyResponse({
      userId: u.userId,
      surveyId: s.id,
      questions: [
        {
          questionId: q[0].id,
          answers: a1,
        },
        {
          questionId: q[1].id,
          answers: a2,
        },
        {
          questionId: q[2].id,
          answers: a3,
        },
      ],
    });
  },

  telemetry: (n: any, l: any, p: any, c: any) => {
    return telemetryService.capture({
      action: "set-interests-goals",
      factor: [
        {
          type: "interest-survey",
          interestedTopics: p,
        },
        {
          type: "skill-level-survey",
          interestedTopics: l,
        },
        {
          type: "certification-survey",
          interestedTopics: c,
        },
      ],
      object: {
        type: "goal",
        objectId: window.btoa(n),
        objectTitle: "Learn a new skill",
      },
    });
  },
};

export default survey;
