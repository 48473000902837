import { z } from "zod";

import http from "@/services/http";

const practiceExamsResponse = z.array(
  z.object({
    practiceExamGuid: z.string(),
    assessmentType: z.string(),
    practiceExamTitle: z.string(),
    maxpercentageScore: z.number().nullish(),
    mostrecentpercentageScore: z.number().nullish(),
    avgrecentpercentageScore: z.number().nullish(),
    totalAttempts: z.number().nullish(),
    practiceExamAttempts: z.array(
      z.object({
        "attempt-id": z.string().nullish(),
        "attempts-remaining": z.number().nullish(),
        "created-at": z.string().nullish(),
        "completed-at": z.string().nullish(),
        state: z.string().nullish(),
        overallPointsAvailable: z.number(),
        overallPointsEarned: z.number().nullish(),
        questionsTotal: z.number(),
        questionsCorrect: z.number(),
        percentageScore: z.number().nullish(),
        attemptOutcome: z.string().nullish(),
      })
    ),
  })
);

export async function fetchExams(guid: string) {
  if (!window.env.PROFILE_API_LOCATION || !guid || guid == "") {
    return;
  }
  const { data } = await http().get(
    `${window.env.PROFILE_API_LOCATION}/practiceExams?practiceExamGuids=${guid}&include_all_attempts=true`
  );

  if (!data) {
    return;
  }
  return practiceExamsResponse.parse(data["practice-exams"]);
}
