import { z } from "zod";

import http from "@/services/http";

const ExamWithCertification = z.array(
  z.object({
    id: z.number(),
    created_at: z.string(),
    updated_at: z.string(),
    deleted_at: z.string().nullish(),
    created_by: z.string(),
    updated_by: z.string(),
    guid: z.string(),
    certification_id: z.number(),
    exam_number: z.string().nullish(),
    acronym: z.string(),
    version: z.string(),
    title: z.string(),
    release_date: z.string().nullish(),
    eol_date: z.string().nullish(),
    duration_sec: z.number(),
    summary: z.string(),
    description: z.string().nullish(),
    type: z.string(),
    topics: z.array(
      z.object({
        id: z.number(),
        created_at: z.string(),
        updated_at: z.string(),
        deleted_at: z.string().nullish(),
        created_by: z.string(),
        updated_by: z.string(),
        topic_name: z.string(),
        guid: z.string(),
        exam_id: z.number(),
        weight: z.number(),
      })
    ),
    prescribed_prep_material: z
      .array(
        z.object({
          id: z.number(),
          created_at: z.string(),
          updated_at: z.string(),
          deleted_at: z.string().nullish(),
          created_by: z.string().nullish(),
          updated_by: z.string(),
          guid: z.string(),
          exam_id: z.number(),
          title: z.string(),
          type: z.string(),
          SortOrder: z.number(),
        })
      )
      .nullish(),
    certification: z.object({
      id: z.number(),
      created_at: z.string(),
      updated_at: z.string(),
      deleted_at: z.string().nullish(),
      created_by: z.string(),
      updated_by: z.string(),
      name: z.string(),
      acronym: z.string(),
      summary: z.string(),
      description: z.string(),
      active: z.number(),
      sort_order: z.number(),
      technology: z.array(z.string()),
      certification_level: z.string(),
      ce_credits: z.number(),
      guid: z.string(),
      job_roles: z.array(z.string()),
      skills_associated: z
        .array(z.string())
        .nullish()
        .or(z.string().default("").nullish()),
      type: z.string(),
      publisher_name: z.string(),
      language: z.string(),
      community_id: z.string(),
      community_name: z.string(),
      tag_line: z.string(),
      justification: z.string(),
      exam: z.object({}).nullish(),
    }),
  })
);

const CourseIdPath = z.object({
  id: z.number(),
  type: z.string(),
  guid: z.string(),
  title: z.string(),
  paths: z.array(
    z.object({
      id: z.number(),
      guid: z.string(),
      type: z.string(),
      name: z.string(),
      acronym: z.string().nullish(),
      display_version: z.string().nullish(),
    })
  ),
});

export async function fetch(Id: string) {
  const { data } = await http()
    .get(`${window.env.CATALOG_API_LOCATION}/courses/${Id}`)
    .catch((e) => {
      return e.response.data;
    });
  return data;
}

export async function carousel(type: string) {
  const { data } = await http().get(
    `${window.env.CATALOG_API_LOCATION}/courses?type=${type}&length=18`
  );

  return data.data;
}

export async function retrieveCatalogItems(
  guids: Array<string>,
  type?: string
) {
  const { data } = await http().post(
    `${window.env.CATALOG_API_LOCATION}/retrieveCatalogItems`,
    {
      guids: guids,
      type: type ? type : null,
    }
  );

  return data.Data;
}

export async function retrieveCatalogById(ids: Array<number>, type?: string) {
  const { data } = await http().post(
    `${window.env.CATALOG_API_LOCATION}/retrieveCatalogItems`,
    {
      ids: ids,
      type: type ? type : null,
    }
  );

  return data.Data;
}

export async function retrieveAllCatalogItems(
  guids: Array<string>,
  ids: Array<number>,
  type?: string
) {
  const { data } = await http().post(
    `${window.env.CATALOG_API_LOCATION}/retrieveCatalogItems`,
    {
      guids: guids.length ? guids : null,
      ids: ids.length ? ids : null,
      type: type ? type : null,
    }
  );

  return data.Data;
}

export async function getCoursePaths(id: number) {
  if (!window.env.PROFILE_API_LOCATION || id < 0) {
    return;
  }
  const { data } = await http().get(
    `${window.env.CATALOG_API_LOCATION}/courses/${id}/paths`
  );

  return CourseIdPath.parse(data);
}

export async function getExam(
  examIds: Array<string>,
  includeCertifications?: boolean,
  includeTopics?: boolean
) {
  const { data } = await http().post(
    `${window.env.CERTIFICATION_API_LOCATION}/exams/search`,
    {
      exam_number: examIds,
      include_certifications: includeCertifications,
      include_topics: includeTopics,
      include_prescribed_prep_material: true,
    }
  );
  return ExamWithCertification.parse(data.data);
}
