<script lang="ts">
import "@cisco-u/tags/cu-tags.js";
import "@cisco-u/links-external/cu-links-external.js";

import { defineComponent } from "vue";

import { sendLinkCtaTelemetry } from "@/utils/ctaTelemetry";
import isUnauth from "@/utils/isUnauth";
import scheduleExam from "@/views/Certifications/assets/images/schedule-cert-exam-img.png?url";

export default defineComponent({
  props: {
    certification: {
      type: Object,
      required: true,
    },
    topics: {
      type: Array,
      required: true,
    },
    currentExam: {
      type: Object,
      required: true,
    },
    exCardConcentration: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      scheduleExam,
    };
  },
  methods: {
    isUnauth,
    scrollToStep2() {
      document.getElementById("step2")?.scrollIntoView(true);
    },
    async sendUnauthTelemetry(title: string) {
      if (this.isUnauth()) {
        await sendLinkCtaTelemetry(this.$route.fullPath, title, true);
      }
    },
  },
});
</script>
<template>
  <div class="px-4 pb-20 lg:px-44">
    <p class="mb-2 mt-5 text-[2.563rem] text-black-light">
      <span id="schedule" class="scroll-mt-[7rem]">Schedule the exam</span>
    </p>
    <p class="text-18">
      Time to get certified! Schedule and pass your core and concentration
      exams.
    </p>

    <a
      @click.prevent="scrollToStep2"
      @keyup.enter.prevent="scrollToStep2"
      @keyup.space.prevent="scrollToStep2"
      class="text-blue"
      >To select a different concentration exam, return to Step 2</a
    >

    <div
      class="flex flex-col lg:flex-row lg:gap-2"
      :class="{ 'mt-4': isUnauth() }"
      v-if="certification?.exam?.length > 0"
    >
      <div class="mx-auto mt-10 w-[40%] lg:mx-0">
        <img :src="scheduleExam" alt="Schedule the Exam" />
      </div>
      <div class="flex w-full flex-row gap-2 lg:w-[60%]">
        <div class="ml-5">
          <p class="mr-2 text-24 md:text-30">Core exam</p>
          <div v-for="exams in certification.exam" :key="exams.id">
            <div v-if="exams.type == 'core'">
              <p
                :class="
                  isUnauth()
                    ? 'mb-[1.5rem] text-18 font-[400]'
                    : 'mb-[5rem] text-22'
                "
              >
                {{ exams.exam_number + " " + exams.acronym }}
              </p>
              <div class="lg:border-r-2 lg:pr-[5rem]">
                <p class="text-11">TIME</p>
                <p class="mb-[2.625rem] text-30">
                  {{ exams.duration_sec / 60 }} min
                </p>
                <p
                  class="font-face mb-[0.5rem]"
                  :class="{
                    'text-xs font-[400] tracking-md': isUnauth(),
                  }"
                  v-if="topics.length > 0"
                >
                  TOPICS COVERED
                </p>
                <div
                  class="flex flex-col gap-2"
                  :class="isUnauth() ? 'mb-[1.5rem]' : 'mb-[5rem]'"
                >
                  <div v-for="topic in topics" :key="topic as string">
                    <cu-tags :text="topic"></cu-tags>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <cu-links-external
            type="line-diagonal"
            text="Schedule exam"
            @click="sendUnauthTelemetry('Go to CertMetrics')"
            url="https://cp.certmetrics.com/cisco/en/schedule/schedule-exam"
            linkTitle="Go to CertMetrics"
            noUnderline="true"
            color="blue-light"
            class="mt-[1.5rem]"
          ></cu-links-external>
        </div>
        <div class="ml-5 lg:ml-20">
          <p class="text-24 md:text-30">Concentration exam</p>
          <p
            :class="
              isUnauth()
                ? 'mb-[1.5rem] text-18 font-normal'
                : 'text-20 mb-[5rem] md:text-22'
            "
          >
            {{ currentExam.exam_number + " " + currentExam.acronym }}
          </p>
          <div>
            <p class="text-11">TIME</p>
            <p class="mb-[2.625rem] text-30">
              {{ exCardConcentration.duration_sec / 60 }} min
            </p>
            <p
              class="font-face mb-[0.5rem]"
              :class="{
                'text-xs font-[400] tracking-md': isUnauth(),
              }"
              v-if="currentExam.topics.length > 0"
            >
              TOPICS COVERED
            </p>
            <div
              class="flex flex-col gap-2"
              :class="isUnauth() ? 'mb-[1.5rem]' : 'mb-[5rem]'"
            >
              <div v-for="topic in currentExam.topics" :key="topic">
                <cu-tags :text="topic.topic_name"></cu-tags>
              </div>
            </div>
          </div>
          <cu-links-external
            type="line-diagonal"
            text="Schedule exam"
            @click="sendUnauthTelemetry('Go to CertMetrics')"
            url="https://cp.certmetrics.com/cisco/en/schedule/schedule-exam"
            linkTitle="Go to CertMetrics"
            noUnderline="true"
            color="blue-light"
            class="mt-[1.5rem]"
          ></cu-links-external>
        </div>
      </div>
    </div>
  </div>
</template>
