<script lang="ts">
import { defineComponent } from "vue";

import RelHeading from "@/views/Search/assets/RelHeading.vue";

export default defineComponent({
  components: { RelHeading },
  methods: {
    toggleSeeTopCourses(type: string) {
      const routerPath = this.$router.resolve({
        name: "explore-courses",
      }).href;
      window.open(routerPath, "_blank");
    },
  },
});
</script>

<template>
  <div data-cy="topCourses">
    <rel-heading
      ref="topCourses"
      type-name="Courses"
      @see-more="toggleSeeTopCourses('courses')"
      result-component="cert-card"
      content-type="course"
      :top-courses="true"
    >
      Check out our newest
    </rel-heading>
  </div>
</template>
