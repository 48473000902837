import { SearchRequest } from "@/services/searchService/types";
import type { FacetFilter } from "@/views/Search/SearchTypes";

export function setType(postObj: SearchRequest): SearchRequest {
  if (postObj.type == "video-series") {
    postObj.type = "video-track";
  }

  if (postObj.type == "event") {
    postObj.type = "webinar";
  }
  return postObj;
}

export function setTenantAnalyticsUrl(postObj: SearchRequest): SearchRequest {
  postObj.tenant = ["apollo"];
  postObj.analytics_tags = ["apollo-search"];
  postObj.url = window.location.href;
  return postObj;
}

export function setPostPage(postObj: SearchRequest): SearchRequest {
  if (postObj.page) {
    postObj.page = JSON.parse(JSON.stringify(postObj.page));
  }
  return postObj;
}

export function setFacetFilters(
  postObj: SearchRequest,
  facetFilters: Array<FacetFilter>
) {
  let filteredFacets = JSON.parse(JSON.stringify(facetFilters));
  filteredFacets = filteredFacets.map((facetSet: any) => {
    return facetSet;
  });
  postObj.facet_filters = filteredFacets;
  // drop if none
  if (!postObj.facet_filters?.length) {
    delete postObj.facet_filters;
  }
  return postObj;
}
