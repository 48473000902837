<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

// @ts-ignore
export default defineComponent({
  props: {
    content: {
      type: Object,
      required: true,
    },
    tags: {
      type: Array<string>,
      default: [],
    },
  },
  computed: {
    ...mapGetters({
      freeId: "content/freeId",
    }),
    freeContent(): boolean {
      return this.content?.content_bundle?.includes(this.freeId);
    },
    newContent(): boolean {
      if (!this.content?.created_at) return false;
      const created_at = new Date(this.content?.created_at);
      created_at.setDate(created_at.getDate() + 30);
      return new Date() < created_at;
    },
    results(): Array<string> {
      let data = this.content.tags || [];
      data = data.concat(this.tags.filter((tag) => !data.includes(tag)));
      if (this.freeContent) {
        data.push("Free");
      }
      if (!data.includes("New") && this.newContent && Array.isArray(data)) {
        data.push("New");
      }
      return data;
    },
    isPartial(): boolean {
      return this.content?.is_partial === 1 && this.content?.type === "path";
    },
  },
});
</script>

<template>
  <cu-tags
    v-for="tag in results"
    :text="tag"
    :key="tag"
    size="s"
    squared=""
    :lightblue="true"
  />
  <cu-tags
    v-if="isPartial"
    text="Special release"
    size="s"
    squared=""
    blue="true"
    ghost=""
  />
</template>
