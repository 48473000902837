import * as address from "@/services/commerceService/address";
import * as countries from "@/services/commerceService/countries";
import * as createOrder from "@/services/commerceService/createOrder";
import * as invoice from "@/services/commerceService/invoice";
import { getOktaProfile } from "@/services/commerceService/okta";
import * as payment from "@/services/commerceService/payment";
import {
  add,
  addLearnerInformation,
  addToCart,
  applyDiscountCoupon,
  getBasket,
  loginUser,
  removeDiscountCoupon,
  removeProduct,
  updateProduct,
  updateProductLineItem,
  validateAndAddProduct,
  validateBasket,
} from "@/services/commerceService/products";
import * as states from "@/services/commerceService/states";
import * as tax from "@/services/commerceService/tax";

export default {
  address,
  invoice,
  states,
  countries,
  createOrder,
  add,
  getBasket,
  validateAndAddProduct,
  addLearnerInformation,
  removeProduct,
  addToCart,
  updateProduct,
  updateProductLineItem,
  loginUser,
  applyDiscountCoupon,
  removeDiscountCoupon,
  validateBasket,
  payment,
  tax,
  getOktaProfile,
};
