import { z } from "zod";

import http from "@/services/http";

const historyType = z
  .object({
    launchDateTime: z.string(),
    objectId: z.string(),
    objectTitle: z.string(),
  })
  .strict();

const browsingHistoryResponse = z
  .object({
    request: z.object({
      uuid: z.string(),
    }),
    contentTypeLaunch: z.object({
      vendorID: z.string(),
      application: z.string(),
      environment: z.string(),
      contentType: z
        .object({
          course: z.array(historyType).nullish(),
          ilt: z.array(historyType).nullish(),
          "instructor-led": z.array(historyType).nullish(),
          "learning-path": z.array(historyType).nullish(),
          podcast: z.array(historyType).nullish(),
          "practice-exam": z.array(historyType).nullish(),
          video: z.array(historyType).nullish(),
          "video-track": z.array(historyType).nullish(),
          webinar: z.array(historyType).nullish(),
          "pre-assessment": z.array(historyType).nullish(),
          "post-assessment": z.array(historyType).nullish(),
          "subscription offer": z.array(historyType).nullish(),
          tutorial: z.array(historyType).nullish(),
        })
        .strict(),
    }),
  })
  .strict();

export async function fetch() {
  const { data } = await http().get(
    `${window.env.PROFILE_API_LOCATION}/browsinghistory`
  );

  return browsingHistoryResponse.parse(data);
}
