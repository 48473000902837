import { z } from "zod";

import http from "@/services/http";

const UserCompletesResponse = z.object({
  cco_id: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  cdl_info: z.any(),
});

export type UserCompletesResponse = z.infer<typeof UserCompletesResponse>;

export async function fetchCdlUserCompletes() {
  const { data } = await http().get(
    `${window.env.PROFILE_API_LOCATION}/cdlUserCompletes`
  );
  return UserCompletesResponse.parse(data);
}
