import { z } from "zod";

import http from "@/services/http";

const courseCompletion = z
  .object({
    courseCompletionDate: z.string().nullish(),
    courseCompletionEventID: z.string().nullish(),
    courseCompletionPercentage: z.string().nullish(),
    courseGUID: z.string().nullish(),
    courseID: z.string().nullish(),
    solomonuuid: z.string().nullish(),
    technology: z.string().nullish(),
    title: z.string().nullish(),
    vendorID: z.string().nullish(),
  })
  .passthrough()
  .nullish();

const pathCompletion = z
  .object({
    pathCompletionDate: z.string().nullish(),
    pathCompletionStatus: z.string().nullish(),
    pathCompletionEventID: z.string().nullish(),
    pathProgressPercentage: z.string().nullish(),
    pathGUId: z.string().nullish(),
    pathId: z.string().nullish(),
    solomonuuid: z.string().nullish(),
    technology: z.string().nullish(),
    pathTitle: z.string().nullish(),
    vendorID: z.string().nullish(),
  })
  .passthrough()
  .nullish();

const tutorialCompletion = z
  .object({
    CompletionDate: z.string().nullish(),
    CompletionEventID: z.string().nullish(),
    CompletionPercentage: z.string().nullish(),
    GUID: z.string().nullish(),
    ID: z.string().nullish(),
    solomonuuid: z.string().nullish(),
    technology: z.string().nullish(),
    title: z.string().nullish(),
    vendorID: z.string().nullish(),
  })
  .passthrough()
  .nullish();

const practiceExamCompletion = z
  .object({
    CompletionDate: z.string().nullish(),
    CompletionEventID: z.string().nullish(),
    courseCompletionPercentage: z.string().nullish(),
    GUID: z.string().nullish(),
    ID: z.string().nullish(),
    solomonuuid: z.string().nullish(),
    technology: z.string().nullish(),
    title: z.string().nullish(),
    vendorID: z.string().nullish(),
  })
  .passthrough()
  .nullish();
const userProgressResponse = z.object({
  achievements: z.object({
    courses: z
      .object({
        completions: z.array(courseCompletion).nullish(),
        "In-progress": z.array(courseCompletion).nullish(),
      })
      .passthrough()
      .nullish(),
    learningpaths: z
      .object({
        "In-progress": z.array(pathCompletion).nullish(),
        completions: z.array(pathCompletion).nullish(),
        enrolled: z.array(pathCompletion).nullish(),
      })
      .passthrough()
      .nullish(),
    tutorials: z
      .object({
        completions: z.array(tutorialCompletion).nullish(),
        "In-progress": z.array(tutorialCompletion).nullish(),
      })
      .passthrough()
      .nullish(),
    practiceExams: z
      .object({
        completions: z.array(practiceExamCompletion).nullish(),
        "In-progress": z.array(z.any()).nullish(),
      })
      .passthrough()
      .nullish(),
  }),
});

export async function fetchUserProgress() {
  if (!window.env.PROFILE_API_LOCATION) {
    return;
  }
  const { data } = await http().get(
    `${window.env.PROFILE_API_LOCATION}/userProgress`
  );

  return userProgressResponse.parse(data);
}
